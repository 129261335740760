<script setup lang="ts">
//#region Import

import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';

// Service / Store
import { saveFileBase64 } from '@/service/vistoria-files';
import { useStepVistoria } from '@/stores/stepVistoria';

// Component
import IconArrowPath from '@/shared/icons/IconArrowPath.vue';
import IconPhoto from '@/shared/icons/IconPhoto.vue';
import Loading from '@/shared/component/show-loading.vue';

// Other
import {
  ID_MODELO_FOTO_ADICIONAL,
  MEDIA_STREAM_CONSTRAINTS_PHOTO,
  TIPO_FOTO_DOCUMENTACAO,
} from '@/global';
import { convertArrayBufferToBase64 } from '@/shared/utils/convert';
import { goVerificar } from '@/shared/utils/routeNavigate';

//#endregion

const vistoriaStore = useStepVistoria();

const videoElement = ref<HTMLVideoElement>();
const canvasElement = ref<HTMLCanvasElement>();
const inputElement = ref<HTMLInputElement>();

const showCamera = ref<boolean>(false);
const showButtonCapture = ref<boolean>(false);
const photoCaptured = ref<boolean>(false);
const loading = ref<boolean>(true);
const srcImage = ref<string | null>(null);

let cameraUser = false;
let cameraStream: MediaStream;

/**
 * Abre o Stream da Camera
 */
const openStreamCamera = async () => {
  loading.value = true;
  showCamera.value = true;
  photoCaptured.value = false;
  srcImage.value = null;

  const constraint = JSON.parse(JSON.stringify(MEDIA_STREAM_CONSTRAINTS_PHOTO));

  if (cameraUser) {
    constraint.video.facingMode = 'user';
  }

  cameraStream = await navigator.mediaDevices.getUserMedia(constraint);
  videoElement.value!.srcObject = cameraStream;
  showButtonCapture.value = true;
  loading.value = false;
};

/**
 * Fecha o Stream da Camera
 */
const closeStreamCamera = () => {
  return new Promise<void>((resolve) => {
    showCamera.value = false;
    showButtonCapture.value = false;

    if (!cameraStream) {
      return resolve();
    }

    const tracks = cameraStream.getTracks();

    tracks.forEach((track: any) => {
      track.stop();
    });

    if (videoElement.value) {
      videoElement.value!.pause();
      videoElement.value!.srcObject = null;
    }

    resolve();
  });
};

/**
 * Troca de Camera (Frontal/Selfie)
 */
const turnCamera = async () => {
  await closeStreamCamera();
  cameraUser = !cameraUser;
  await openStreamCamera();
};

/**
 * Captura a Foto do Stream atual
 */
const capturePhoto = async () => {
  if (canvasElement.value && videoElement.value) {
    await playSound();

    canvasElement.value.width = videoElement.value.videoWidth;
    canvasElement.value.height = videoElement.value.videoHeight;
    canvasElement.value.getContext('2d')!.drawImage(videoElement.value, 0, 0);

    srcImage.value = canvasElement.value!.toDataURL('image/jpeg');
    photoCaptured.value = true;

    await closeStreamCamera();
  }
};

/**
 * Verificação da foto capturada
 */
const checkPhotoCaptured = async () => {
  if (srcImage.value) {
    await saveFileAndRedirect(srcImage.value);
  }
};

/**
 * Salva o arquivo e redireciona
 * @param value arquivo em base64
 */
const saveFileAndRedirect = async (value: string, type?: string) => {
  let idFotoAdicional;

  if (
    vistoriaStore.currentVistoriaEtapa!.fotoVideo?.idModeloFoto ===
    ID_MODELO_FOTO_ADICIONAL
  ) {
    idFotoAdicional =
      vistoriaStore.currentVistoriaEtapa!.fotoVideo?.idFotoAdicional ||
      uuidv4();
  }

  await saveFileBase64(
    vistoriaStore.currentVistoriaEtapa!.idEtapa,
    vistoriaStore.vistoria!.chave,
    value,
    type,
    idFotoAdicional,
  );
  goVerificar();
};

/**
 * Play de video
 */
const playSound = async () => {
  const audioUrl = new URL('@/assets/mp3/camera-capture.mp3', import.meta.url)
    .href;
  const audio = new Audio(audioUrl);
  await audio.play();
};

/**
 * Arquivo selecionado através da galeria
 */
const selectedFile = async () => {
  if (inputElement.value?.files) {
    const file: File = inputElement.value.files[0];

    if (file) {
      loading.value = true;
      await closeStreamCamera();

      const type = file.type;
      const buffer = await file.arrayBuffer();
      const fileBase64 = await convertArrayBufferToBase64(buffer, type);
      await saveFileAndRedirect(fileBase64, type);
    }
  }
};

/**
 * Exibe o botão de Galeria
 */
const showGalleryButton = computed(() => {
  const fotosVideo = vistoriaStore.currentVistoriaEtapa?.fotoVideo;

  if (!fotosVideo) {
    return false;
  }

  return (
    fotosVideo.tipoFoto === TIPO_FOTO_DOCUMENTACAO ||
    fotosVideo.idModeloFoto === ID_MODELO_FOTO_ADICIONAL
  );
});

onMounted(async () => {
  await openStreamCamera();
});

onBeforeUnmount(async () => {
  await closeStreamCamera();
});
</script>

<template>
  <Loading :show="loading" />

  <div class="capture-photo">
    <div class="capture-photo__buttons">
      <div
        v-if="showButtonCapture"
        class="capture-photo__buttons__auxiliar fade-in"
      >
        <div v-if="showGalleryButton">
          <label for="input-file">
            <IconPhoto />
          </label>
          <input
            type="file"
            id="input-file"
            style="display: none"
            accept=".jpg,.jpeg,.png,.pdf,"
            ref="inputElement"
            @change="selectedFile()"
          />
        </div>
      </div>

      <div
        class="capture-photo__buttons__capture-photo fade-in"
        v-if="showButtonCapture"
      >
        <span @click="capturePhoto()"></span>
      </div>

      <div
        class="capture-photo__buttons__auxiliar fade-in"
        @click="turnCamera()"
        v-if="showButtonCapture"
      >
        <div>
          <IconArrowPath />
        </div>
      </div>
    </div>

    <div class="capture-photo__retry" v-if="photoCaptured">
      <span @click="openStreamCamera()">Repetir</span>
      <span @click="checkPhotoCaptured()">Usar Foto</span>
    </div>

    <video
      v-if="showCamera"
      ref="videoElement"
      playsInline="true"
      autoPlay="true"
      muted="true"
    ></video>

    <img v-if="srcImage" :src="srcImage" class="fade-in" />

    <div style="display: none">
      <canvas ref="canvasElement"></canvas>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.capture-photo {
  background-color: #212322;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    max-width: 100%;
    max-height: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &__buttons,
  &__retry {
    position: absolute;
    z-index: 1;
    bottom: 1.2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__auxiliar {
      margin: 0 0.8rem;
      width: 2.4rem;
      height: 2.4rem;

      > div {
        cursor: pointer;
        background-color: var(--white);
        border-radius: 50%;
        color: var(--primary);
        padding: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;

        label {
          cursor: pointer;
          width: 100%;
          height: 100%;
          display: flex;
        }

        svg {
          display: block;
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }

    &__capture-photo {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        cursor: pointer;
        display: block;
        width: 3.6rem;
        height: 3.6rem;
        background: rgba($color: #ffffff, $alpha: 0.5);
        border: 3px solid #ffffff;
        border-radius: 50%;

        &:active,
        &:hover {
          background-color: var(--white);
        }
      }
    }
  }

  &__retry {
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);

    span {
      cursor: pointer;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      margin: 0.8rem 0;
      display: block;
      text-align: center;
      color: var(--white);
      background: transparent;
      width: 100%;
    }
  }
}

@media (orientation: landscape) {
  .capture-photo {
    &__buttons {
      flex-direction: column-reverse;
      right: 1.2rem;
      width: initial;
      height: 100%;
      bottom: 0;

      &__auxiliar {
        margin: 0.8rem 0;
      }
    }
  }
}

// https://animista.net/play/entrances/fade-in/fade-in
.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
