import { useStepVistoria } from '@/stores/stepVistoria';

export function getCurrentPosition(
  options = {},
): Promise<
  GeolocationPosition | { coords: { latitude: null; longitude: null } }
> {
  const vistoriaStore = useStepVistoria();

  if (!vistoriaStore.userPermissions?.currentPosition) {
    return new Promise((resolve) => {
      resolve({ coords: { latitude: null, longitude: null } });
    });
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}
