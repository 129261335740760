import type { VistoriaEtapas } from '@/model/vistoria-etapas.interface';

export function getAssuntoImplementoAgregado(
  value: VistoriaEtapas | undefined,
) {
  if (!value) {
    return '';
  }

  const tipo = value.tipo as 'DADOS_IMPLEMENTO' | 'DADOS_AGREGADO';

  const assunto = {
    DADOS_IMPLEMENTO: 'implemento',
    DADOS_AGREGADO: 'agregado',
  };

  return assunto[tipo] || '';
}
