<script setup lang="ts">
//#region Imports

import { ref } from 'vue';
import { useOnline } from '@vueuse/core';
import { useStepVistoria } from '@/stores/stepVistoria';

//#endregion

const online = useOnline();
const vistoriaStore = useStepVistoria();

const countItemSync = ref<number>(vistoriaStore.countPrepared);

defineProps({
  model: {
    type: String,
  },
});

const emit = defineEmits(['go-envio']);

const goEnvio = () => {
  emit('go-envio');
};

vistoriaStore.$subscribe(() => {
  countItemSync.value = vistoriaStore.countPrepared;
});
</script>

<template>
  <!-- Botão Envio e Status -->
  <div class="footer-send" v-if="countItemSync > 0">
    <div class="footer-send__container">
      <div class="btn-vistoria" v-if="model === 'primary'">
        <button
          class="btn btn-primary"
          @click="goEnvio()"
          :disabled="countItemSync === 0 || !online"
          data-testId="save-inspection-button"
        >
          Salvar Vistoria
        </button>
      </div>

      <p class="texto" v-if="online">
        Existem
        <span class="badge" :data-badge="countItemSync">etapas</span> a serem
        salvas
      </p>

      <p class="texto footer-send__off" v-if="!online">
        Não é possível salvar a vistoria, aparentemente você está sem internet
      </p>

      <div class="secondary btn-vistoria" v-if="model !== 'primary'">
        <button
          class="btn btn-link"
          @click="goEnvio()"
          data-testId="view-steps-button"
        >
          Visualizar Etapas
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.footer-send {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--white);
  padding-top: 0.4rem;
  border-top: 1px solid #ccc;

  &__container {
    padding: 0.4rem;
    margin: 0 auto;

    .btn-vistoria {
      margin-bottom: 0.8rem;
    }

    .texto {
      text-align: center;
      margin-bottom: 0.8rem;
    }

    .secondary.btn-vistoria {
      margin-bottom: 0;
    }
  }

  &__off {
    color: var(--alert);
  }
}
</style>
