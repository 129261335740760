import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { v4 as uuidv4 } from 'uuid';

// Model
import type { Vistoria } from '@/model/vistoria.interface';
import type {
  VistoriaEtapaDadosPessoais,
  VistoriaEtapas,
} from '@/model/vistoria-etapas.interface';

// Service
import { db } from '../db';
import { updateVistoriaEtapasStore } from '@/service/store';

// Other
import {
  STATUS_PERMITE_EDICAO_DADOS,
  TIPO_ETAPA_DADOS_PESSOAIS,
} from '@/global';

/**
 * Recupera a Etapa Dados Pessoais para salvar no IndexedDB
 * @param vistoria dados da vistoria
 * @param ordem ordem da vistoria
 */
export function getVistoriaEtapaDadosPessoaisSaveIndexedDB(
  vistoria: Vistoria,
  ordem: number,
) {
  const idStatus = vistoria.id_Status;

  if (!STATUS_PERMITE_EDICAO_DADOS.includes(idStatus)) {
    return null;
  }

  const chave = vistoria.chave;
  const sync = vistoria.validatedDadosPessoais;
  const dadosPessoais = vistoria.dadosPessoais;

  dadosPessoais.validatedForm = vistoria.validatedDadosPessoais;

  // Sanitize
  if (dadosPessoais?.dataNascimento) {
    dadosPessoais.dataNascimento = dayjs(dadosPessoais.dataNascimento).format(
      'DD/MM/YYYY',
    );
  }

  return {
    idEtapa: uuidv4(),
    chave,
    ordem,
    nomeEtapa: 'Dados Pessoais',
    obrigatorio: true,
    tipo: TIPO_ETAPA_DADOS_PESSOAIS,
    sync,
    dadosPessoais,
  } as VistoriaEtapas;
}

/**
 * Salvar os dados pessoais
 * @param chave chave da proposta/vistoria
 * @param idEtapa id da etapa da vistoria
 * @param dados dados pessoais
 */
export async function saveDadosPessoais(
  chave: string,
  idEtapa: string,
  dados: VistoriaEtapaDadosPessoais,
) {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('[idEtapa+chave]')
    .equals([idEtapa, chave])
    .first();

  // Sanitize
  dados.cpfRepresentante = dados?.cpfRepresentante ?? null;
  dados.representanteLegal = dados?.representanteLegal ?? null;
  dados.dataNascimento = dados?.dataNascimento ?? null;
  dados.telefone = dados?.telefone?.length ? dados!.telefone : null;

  if (vistoriaEtapas) {
    vistoriaEtapas!.dadosPessoais = dados;
    vistoriaEtapas!.prepared = true;
    vistoriaEtapas!.sync = false;

    await db.vistoriaEtapas.update([idEtapa, chave], vistoriaEtapas);

    await updateVistoriaEtapasStore(chave);
  }
}

/**
 * Recupera a Etapa Dados Pessoais não sicronizada
 * @param chave da proposta/vistoria
 */
export async function getVistoriaEtapaDadosPessoaisNotSync(chave: string) {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .toArray();

  const vistoriaEtapa = vistoriaEtapas.find(
    (x) => x.tipo === TIPO_ETAPA_DADOS_PESSOAIS,
  );

  if (vistoriaEtapa) {
    const result = vistoriaEtapa?.dadosPessoais || null;

    if (!result) {
      return result;
    }

    if (!vistoriaEtapa.sync && vistoriaEtapa.prepared) {
      // Saniteze
      if (vistoriaEtapa.dadosPessoais!.dataNascimento) {
        dayjs.extend(customParseFormat);
        vistoriaEtapa.dadosPessoais!.dataNascimento = dayjs(
          vistoriaEtapa.dadosPessoais!.dataNascimento,
          'DD/MM/YYYY',
        ).format('YYYYMMDD');
      }

      return vistoriaEtapa;
    }
  }

  return null;
}
