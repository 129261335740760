import router from '.';

// Model
import type { VistoriaEtapas } from '@/model/vistoria-etapas.interface';

// Service
import { useStepVistoria } from '@/stores/stepVistoria';

// Other
import {
  ROTA_PERMISSOES,
  ROTA_VERIFICAR,
  TIPO_ETAPA_ASSINATURA,
} from '@/global';

/**
 * Se já existe foto/video capturado exibe a tela de verificação
 * @param chave da proposta/vistoria
 * @param vistoriaEtapa dados da etapa atual
 */
export function checkAndGoVerificar(
  chave: string,
  vistoriaEtapa: VistoriaEtapas,
) {
  if (vistoriaEtapa.fotoVideo?.aprovada === false) {
    return;
  }

  if (vistoriaEtapa.tipo === TIPO_ETAPA_ASSINATURA) {
    return;
  }

  if (vistoriaEtapa.fotoVideo?.nome?.id) {
    router.push({ name: ROTA_VERIFICAR, params: { chave } });
  }
}

/**
 * Verificar se o Store já foi carregado
 * @returns {boolean}
 */
export function checkStoreLoad(): boolean {
  const vistoriaStore = useStepVistoria();

  const vistoria = vistoriaStore.vistoria;
  const vistoriaEtpas = vistoriaStore.vistoriaEtpas;

  return vistoria && vistoriaEtpas ? true : false;
}

/**
 * Verifica se tem permissão do usuário
 * @param chave da proposta/vistoria
 */
export function checkAndGoPermissoes(chave: string): boolean {
  const vistoriaStore = useStepVistoria();

  const result = vistoriaStore.permissions;

  if (!result) {
    router.replace({ name: ROTA_PERMISSOES, params: { chave } });
  }

  return result;
}
