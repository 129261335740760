import axios, { type AxiosRequestConfig } from 'axios';

/**
 * Realiza a requisição
 * @param reqOptions options da requisição
 * @returns
 */
export const doRequest = async (reqOptions: AxiosRequestConfig) => {
  let error;
  let data;

  try {
    const response = await axios.request(reqOptions);
    data = response.data;
  } catch (e) {
    error = e;
  }

  return {
    data,
    error,
  };
};
