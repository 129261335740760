<script lang="ts" setup>
//#region Import

import { ref } from 'vue';

// Component
import IconVideoCamera from '@/shared/icons/IconVideoCamera.vue';
import ModalBadFile from '@/shared/component/modal-bad-file.vue';
import ModalWaitSaveFile from '@/shared/component/modal-wait-save-file.vue';

// Service
import { insertEtapaFotoVideoAdicional } from '@/service/vistoria-etapas';
import { saveVideo } from '@/service/vistoria-files';
import { useStepVistoria } from '@/stores/stepVistoria';

// Other
import { ID_MODELO_VIDEO_ADICIONAL, TIPO_ETAPA_VIDEO } from '@/global';
import { goVerificar } from '@/shared/utils/routeNavigate';

//#endregion

const vistoriaStore = useStepVistoria();

const inputElement = ref<HTMLInputElement>();
const showModal = ref<boolean>(false);
const showModalLoading = ref<boolean>(false);

/**
 * Fecha o modal
 */
const closeModal = () => {
  showModal.value = false;
};

/**
 * Validar o tipo de arquivo
 * @param type tipo do arquivo
 */
const validateFileType = (type: string) => {
  if (type.indexOf('video/') >= 0) {
    return true;
  }

  showModal.value = true;
  return false;
};

/**
 * Arquivo selecionado através da galeria
 */
const selectedFile = async () => {
  if (inputElement.value?.files) {
    const file: File = inputElement.value.files[0];

    if (file) {
      if (!validateFileType(file.type)) {
        return;
      }

      showModalLoading.value = true;

      if (props.fotoAdicional) {
        await addVideoAdicional();
      }

      const type = file.type;
      const value = new Blob([await file.arrayBuffer()]);

      await saveVideo(
        vistoriaStore.currentVistoriaEtapa!.idEtapa,
        vistoriaStore.vistoria!.chave,
        [value],
        type,
      );

      goVerificar();
    }
  }
};

const props = defineProps({
  fotoAdicional: {
    type: Boolean,
    default: false,
  },
});

/**
 * Adicona Video Adicional
 */
const addVideoAdicional = async () => {
  const idEtapa = await insertEtapaFotoVideoAdicional(
    vistoriaStore.vistoria!.chave,
    ID_MODELO_VIDEO_ADICIONAL,
  );
  vistoriaStore.setCurrentVistoriaEtapaRetry(idEtapa);
};
</script>

<template>
  <div
    v-if="
      vistoriaStore.currentVistoriaEtapa?.tipo === TIPO_ETAPA_VIDEO ||
      props.fotoAdicional
    "
  >
    <div
      :class="{
        'card buttonTakeGalleryFile': !props.fotoAdicional,
        'btn-vistoria': props.fotoAdicional,
      }"
      role="button"
    >
      <label for="input-video" v-if="!props.fotoAdicional">
        <span>Gravar Vídeo</span>
        <IconVideoCamera />
      </label>
      <label for="input-video" v-else class="btn">
        Incluir Vídeo Adiconal
      </label>
      <input
        type="file"
        id="input-video"
        style="display: none"
        accept="video/*"
        ref="inputElement"
        capture="environment"
        @change="selectedFile()"
      />
    </div>

    <ModalBadFile :show="showModal" @close="closeModal()" />

    <ModalWaitSaveFile :show="showModalLoading" />
  </div>
</template>

<style lang="scss">
.buttonTakeGalleryFile {
  margin-top: 0.8rem;
  color: var(--primary);

  label {
    display: flex;
    align-items: center;
    flex-direction: row !important;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
  }

  span {
    font-weight: 500;
    display: block;
  }

  svg {
    display: block;
    width: 2rem;
    height: 2rem;
  }
}

.btn-vistoria {
  margin-top: 0.8rem;

  label {
    width: 100%;
    text-transform: uppercase;
    font-size: 0.7rem;
  }
}
</style>
