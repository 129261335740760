// https://gist.github.com/sh-ravan/9da7fc2724fa19cb3647cc8354fca7fe
export async function rotate(srcBase64: string, degrees = 90) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const image = new Image();

  if (!ctx) {
    return srcBase64;
  }

  image.src = srcBase64;
  await image.decode();

  canvas.width = degrees % 180 === 0 ? image.width : image.height;
  canvas.height = degrees % 180 === 0 ? image.height : image.width;

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((degrees * Math.PI) / 180);
  ctx.drawImage(image, image.width / -2, image.height / -2);

  return canvas.toDataURL();
}

/**
 * Redimenciona a imagem
 * @param file imagem a ser redimencionada
 * @param maxSize tamanho máximo da imagem
 */
export function resizeImage(file: File, maxSize: number) {
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');

  const resize = () => {
    let { width, height } = image;

    if (width > height && width > maxSize) {
      height *= maxSize / width;
      width = maxSize;
    } else if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d')!.drawImage(image, 0, 0, width, height);

    const dataUrl = canvas.toDataURL('image/jpeg');
    return dataUrl;
  };

  return new Promise<string>((resolve) => {
    reader.onload = (event) => {
      image.onload = () => resolve(resize());
      image.src = event.target?.result as string;
    };

    reader.readAsDataURL(file);
  });
}
