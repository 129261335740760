import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { v4 as uuidv4 } from 'uuid';

// Model
import type { Vistoria } from '@/model/vistoria.interface';
import type {
  VistoriaEtapaDadosCnh,
  VistoriaEtapas,
} from '@/model/vistoria-etapas.interface';

// Service
import { db } from '../db';
import { updateVistoriaEtapasStore } from '@/service/store';

// Other
import { STATUS_PERMITE_EDICAO_DADOS, TIPO_ETAPA_DADOS_CNH } from '@/global';

/**
 * Recupera a Etapa Dados CNH para salvar no IndexedDB
 * @param vistoria dados da vistoria
 * @param ordem ordem da vistoria
 */
export function getVistoriaEtapaDadosCnhSaveIndexedDB(
  vistoria: Vistoria,
  ordem: number,
) {
  const idStatus = vistoria.id_Status;

  if (!STATUS_PERMITE_EDICAO_DADOS.includes(idStatus)) {
    return null;
  }

  const chave = vistoria.chave;
  const sync = vistoria.validatedDadosCNH;
  const dadosCNH = vistoria.dadosCNH;

  dadosCNH.validatedForm = vistoria.validatedDadosCNH;

  // Sanitize
  if (dadosCNH?.dataVencimentoCnh) {
    dadosCNH.dataVencimentoCnh = dayjs(dadosCNH.dataVencimentoCnh).format(
      'DD/MM/YYYY',
    );
  }

  return {
    idEtapa: uuidv4(),
    chave,
    ordem,
    nomeEtapa: 'Dados da CNH',
    obrigatorio: true,
    tipo: TIPO_ETAPA_DADOS_CNH,
    sync,
    dadosCNH,
  } as VistoriaEtapas;
}

/**
 * Salvar os dados da CNH
 * @param chave chave da proposta/vistoria
 * @param idEtapa id da etapa da vistoria
 * @param dados dados da CNH
 */
export async function saveDadosCNH(
  chave: string,
  idEtapa: string,
  dados: VistoriaEtapaDadosCnh,
) {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('[idEtapa+chave]')
    .equals([idEtapa, chave])
    .first();

  if (vistoriaEtapas) {
    vistoriaEtapas!.dadosCNH = dados;
    vistoriaEtapas!.prepared = true;
    vistoriaEtapas!.sync = false;

    await db.vistoriaEtapas.update([idEtapa, chave], vistoriaEtapas);

    await updateVistoriaEtapasStore(chave);
  }
}

/**
 * Recupera a Etapa Dados CNH não sincronizada
 * @param chave da proposta/vistoria
 */
export async function getVistoriaEtapaDadosCnhNotSync(chave: string) {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .toArray();

  const vistoriaEtapa = vistoriaEtapas.find(
    (x) => x.tipo === TIPO_ETAPA_DADOS_CNH,
  );

  if (vistoriaEtapa) {
    const result = vistoriaEtapa?.dadosCNH || null;

    if (!result) {
      return result;
    }

    if (!vistoriaEtapa.sync && vistoriaEtapa.prepared) {
      // Saniteze
      if (vistoriaEtapa.dadosCNH!.dataVencimentoCnh) {
        dayjs.extend(customParseFormat);
        vistoriaEtapa.dadosCNH!.dataVencimentoCnh = dayjs(
          vistoriaEtapa.dadosCNH!.dataVencimentoCnh,
          'DD/MM/YYYY',
        ).format('YYYYMMDD');
      }

      return vistoriaEtapa;
    }
  }

  return null;
}
