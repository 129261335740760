<script lang="ts" setup>
//#region Imports

import { ref } from 'vue';
import { useRouter } from 'vue-router';

// Component
import IconClipboard from '@/shared/icons/IconClipboard.vue';

// Store
import { useStepVistoria } from '@/stores/stepVistoria';

// Other
import { ROTA_RESUMO } from '@/global';

//#endregion

const router = useRouter();
const vistoriaStore = useStepVistoria();

const vistoria = ref(vistoriaStore!.vistoria);
const maxProgress = 100;

const goResumo = () => {
  router.replace({ name: ROTA_RESUMO });
};
</script>

<template>
  <div class="header card header-progress">
    <div class="card-body">
      <div>
        <!-- Etapas -->
        <span class="etapas">
          Etapa {{ vistoriaStore.currentStep }} de {{ vistoriaStore.totalStep }}
        </span>

        <!-- Dados do Veículo -->
        <span class="texto" v-if="vistoria?.placa">
          Placa: {{ vistoria?.placa }}</span
        >
        <span class="texto" v-if="!vistoria?.placa && vistoria?.chassi">
          Chassi: {{ vistoria?.chassi }}</span
        >
      </div>
      <IconClipboard @click="goResumo()" />
    </div>
    <progress
      class="progress"
      :value="vistoriaStore.percentageCompleted"
      :max="maxProgress"
    ></progress>
  </div>
</template>

<style src="./header.scss"></style>
