<script lang="ts" setup>
//#region Imports

import { computed, onMounted, ref } from 'vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { vMaska } from 'maska';

// Model
import type { VistoriaEtapaDadosVeiculo } from '@/model/vistoria-etapas.interface';
import type { VistoriaCoresCombustivel } from '@/model/vistoria.interface';

// Service
import { saveDadosVeiculo } from '@/service/vistoria-etapas/dados-veiculo';
import { useStepVistoria } from '@/stores/stepVistoria';

// Component
import IconCheck from '@/shared/icons/IconCheck.vue';
import Loading from '@/shared/component/show-loading.vue';

// Other
import {
  LIST_ESTADO_PNEUS,
  LIST_TIPO_CAMBIO,
  TIPO_PROPOSTA_SUBSTITUICAO,
  TIPO_VEICULO_MOTO,
} from '@/global';
import {
  validateAnoDUT,
  validateChassi,
  validatePlaca,
  validatePlacaSubstituicao,
  validateRENAVAM,
} from '@/shared/validate';

//#endregion

const vistoriaStore = useStepVistoria();
const loading = ref<boolean>(true);
const vistoria = vistoriaStore.vistoria;

const showQtdPortas = ref<boolean>(true);
const vistoriaSomenteAgregado = ref<boolean>(false);
const dadosVeiculo = ref<VistoriaEtapaDadosVeiculo>();

const state = ref<VistoriaEtapaDadosVeiculo>({
  idProposta: 0,
  anoExercicioDut: '',
  qtdPortas: '',
  cor: '',
  combustivel: '',
  km: '',
  renavam: null,
  chassi: null,
  quantidadePassageiro: '',
  estadoPneu: '',
  tipoCambio: '',
  numeroMotor: '',
  validatedForm: false,
});

const validationRules = computed(() => {
  let baseValidation = {
    anoExercicioDut: { required, validateAnoDUT },
    cor: { required },
    combustivel: { required },
    renavam: { required, validateRENAVAM },
    chassi: { required, validateChassi },
    quantidadePassageiro: { required },
    estadoPneu: { required },
    tipoCambio: { required },
  } as any;

  if (vistoria!.tipoProposta === TIPO_PROPOSTA_SUBSTITUICAO) {
    baseValidation.placaSubstituicao = {
      required,
      validatePlaca,
      validatePlacaSubstituicao: validatePlacaSubstituicao(vistoria!.placa),
    };
  }

  if (dadosVeiculo.value?.codTipoVeiculo !== TIPO_VEICULO_MOTO) {
    baseValidation.qtdPortas = { required };
  }

  if (vistoriaSomenteAgregado.value) {
    delete baseValidation.qtdPortas;
    delete baseValidation.combustivel;
    delete baseValidation.quantidadePassageiro;
    delete baseValidation.estadoPneu;
    delete baseValidation.tipoCambio;
  }

  return baseValidation;
});

const v$ = useVuelidate(validationRules, state, { $autoDirty: true });

const listCores = ref<VistoriaCoresCombustivel[]>();
const listCombustivel = ref<VistoriaCoresCombustivel[]>();

/**
 * Sanitiza os dados do veículo
 * @param value dados do veículo
 */
const sanitize = (value: VistoriaEtapaDadosVeiculo) => {
  value.chassi = value.chassi?.toUpperCase();

  value.numeroMotor = value?.numeroMotor || '1';
  value.numeroMotor = value.numeroMotor?.toUpperCase();

  value.km = value?.km || 1;

  return value;
};

/**
 * Avança para o próximo etapa
 */
const nextStep = async () => {
  const chave = vistoriaStore!.vistoria!.chave;
  const idEtapa = vistoriaStore!.currentVistoriaEtapa!.idEtapa;
  const dados: VistoriaEtapaDadosVeiculo = sanitize(
    Object.assign({}, state.value),
  );

  const validForm = await v$.value.$validate();

  if (validForm) {
    dados.validatedForm = true;
    await saveDadosVeiculo(chave, idEtapa, dados);
    await vistoriaStore.nextVistoriaEtapa();
  }
};

/**
 * Recupea lista de cores
 */
const getListCores = (current: string | null) => {
  listCores.value = vistoriaStore.vistoria!.cores;

  if (current) {
    const find = listCores.value?.find((x) => x.descricao === current);
    if (!find) {
      listCores.value?.push({ descricao: current });
    }
  }
};

/**
 * Recupa lista de combustível
 */
const getListCombustivel = (current?: string) => {
  listCombustivel.value = vistoriaStore.vistoria!.combustiveis;

  if (current) {
    const find = listCombustivel.value?.find((x) => x.descricao === current);
    if (!find) {
      listCombustivel.value?.push({ descricao: current });
    }
  }
};

/**
 * Recupera os dados preenchidos anteriormente
 */
const getPreviousData = () => {
  dadosVeiculo.value = vistoriaStore.currentVistoriaEtapa?.dadosVeiculo;

  if (dadosVeiculo.value) {
    state.value = Object.assign({}, dadosVeiculo.value);
  }

  state.value.idProposta = vistoriaStore.vistoria!.idProposta;
  showQtdPortas.value =
    dadosVeiculo.value?.codTipoVeiculo !== TIPO_VEICULO_MOTO;

  vistoriaSomenteAgregado.value = dadosVeiculo.value?.somenteAgregado || false;

  getListCores(dadosVeiculo.value!.cor);
  getListCombustivel(dadosVeiculo.value?.combustivel);
};

onMounted(async () => {
  getPreviousData();

  // Tempo para atualizar front
  setTimeout(() => {
    loading.value = false;
  }, 300);
});
</script>

<template>
  <Loading :show="loading" />

  <div class="container veiculo__container">
    <span class="title">Dados do Veículo</span>

    <span class="texto pb-2"
      >Preencha com os dados do seu veículo e as informações do setor
      financeiro</span
    >

    <div class="veiculo__container__form">
      <div class="divider" data-content="Veículo"></div>

      <div
        class="mb-2"
        v-if="vistoria?.tipoProposta === TIPO_PROPOSTA_SUBSTITUICAO"
      >
        <!-- Placa Contratada -->
        <div class="form-group">
          <label class="form-label label-sm">Placa Contratada:*</label>
          <input
            class="form-input"
            type="text"
            disabled
            v-model="vistoria.placa"
            data-testId="license-plate-input"
          />
        </div>

        <!-- Placa Substituída -->
        <div
          class="form-group"
          :class="{ 'has-error': v$.placaSubstituicao.$errors.length }"
        >
          <label class="form-label label-sm" for="input-placaSubstituicao"
            >Placa Substituída:*</label
          >
          <input
            class="form-input"
            type="text"
            id="input-placaSubstituicao"
            placeholder="Informe a placa antiga"
            v-maska
            data-maska="@@@-****"
            v-model="state.placaSubstituicao"
            data-testId="new-license-plate-input"
          />
          <p class="form-input-hint">Placa Substituída inválida</p>
        </div>
      </div>

      <!-- Ano do CRLV -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.anoExercicioDut.$errors.length }"
      >
        <label class="form-label label-sm" for="input-anoExercicioDut"
          >Ano Exercício CRLV:*</label
        >
        <input
          class="form-input"
          type="number"
          id="input-anoExercicioDut"
          placeholder="Informe o ano do CRLV"
          v-maska
          data-maska="####"
          min="2000"
          max="2999"
          v-model="state.anoExercicioDut"
          data-testId="crlv-year-input"
        />
        <p class="form-input-hint">Ano do CRLV inválido</p>
      </div>

      <!-- Quantidade de Portas -->
      <div
        v-if="showQtdPortas && !vistoriaSomenteAgregado"
        class="form-group"
        :class="{ 'has-error': v$?.qtdPortas?.$errors.length }"
      >
        <label class="form-label label-sm" for="input-qtdPortas"
          >Quantidade de Portas:*</label
        >
        <input
          class="form-input"
          type="number"
          id="input-qtdPortas"
          placeholder="Informe a quantidade de portas"
          v-maska
          data-maska="##"
          min="0"
          max="99"
          v-model="state.qtdPortas"
          data-testId="number-of-doors-input"
        />
        <p class="form-input-hint">Quantidade de portas inválida</p>
      </div>

      <!-- Cilindradas -->
      <div
        class="form-group"
        v-if="dadosVeiculo?.cilindradas && !vistoriaSomenteAgregado"
      >
        <label class="form-label label-sm">Cilindradas:</label>
        <input
          class="form-input"
          type="text"
          disabled
          v-model="dadosVeiculo!.cilindradas"
          data-testId="cylinder-input"
        />
      </div>

      <!-- Cor -->
      <div class="form-group" :class="{ 'has-error': v$.cor.$errors.length }">
        <label class="form-label label-sm">Cor:*</label>
        <select
          class="form-select"
          v-model="state.cor"
          data-testId="color-select"
        >
          <option v-for="(cor, index) in listCores" :key="index">
            {{ cor.descricao }}
          </option>
        </select>
        <p class="form-input-hint">Selecione a cor do veículo</p>
      </div>

      <!-- Combustível -->
      <div
        v-if="!vistoriaSomenteAgregado"
        class="form-group"
        :class="{ 'has-error': v$?.combustivel?.$errors.length }"
      >
        <label class="form-label label-sm">Combustível:*</label>
        <select
          class="form-select"
          v-model="state.combustivel"
          data-testId="fuel-select"
        >
          <option v-for="(combustivel, index) in listCombustivel" :key="index">
            {{ combustivel.descricao }}
          </option>
        </select>
        <p class="form-input-hint">Selecione o combustível do veículo</p>
      </div>

      <!-- Quilometragem -->
      <div class="form-group" v-if="!vistoriaSomenteAgregado">
        <label class="form-label label-sm" for="input-km">Quilometragem</label>
        <input
          class="form-input"
          type="number"
          id="input-km"
          placeholder="Informe a quilometragem"
          v-maska
          data-maska="#######"
          v-model="state.km"
          data-testId="mileage-input"
        />
      </div>

      <!-- Renavam -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.renavam.$errors.length }"
      >
        <label class="form-label label-sm" for="input-renavam">RENAVAM:*</label>
        <input
          class="form-input"
          type="tel"
          id="input-renavam"
          placeholder="Informe o número do RENAVAM"
          v-maska
          data-maska="###########"
          v-model="state.renavam"
          data-testId="renavam-input"
        />
        <p class="form-input-hint">RENAVAM inválido</p>
      </div>

      <!-- Chassi -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.chassi.$errors.length }"
      >
        <label class="form-label label-sm" for="input-chassi">Chassi:*</label>
        <input
          class="form-input"
          type="text"
          id="input-chassi"
          placeholder="Informe o número do chassi"
          v-model="state.chassi"
          data-testId="chassis-input"
        />
        <p class="form-input-hint">Chassi inválido</p>
      </div>

      <!-- Quantidade de Passageiros -->
      <div
        v-if="!vistoriaSomenteAgregado"
        class="form-group"
        :class="{ 'has-error': v$?.quantidadePassageiro?.$errors.length }"
      >
        <label class="form-label label-sm" for="input-quantidadePassageiro"
          >Quantidade de Passageiros:*</label
        >
        <input
          class="form-input"
          type="number"
          id="input-quantidadePassageiro"
          placeholder="Informe a quantidade de passageiros"
          v-maska
          data-maska="##"
          min="0"
          max="99"
          v-model="state.quantidadePassageiro"
          data-testId="passengers-input"
        />
        <p class="form-input-hint">Quantidade de passageiros inválido</p>
      </div>

      <!-- Estados dos Pneus -->
      <div
        v-if="!vistoriaSomenteAgregado"
        class="form-group"
        :class="{ 'has-error': v$?.estadoPneu?.$errors.length }"
      >
        <label class="form-label label-sm"
          >Estado de conservação dos Pneus:*</label
        >
        <select
          class="form-select"
          v-model="state.estadoPneu"
          data-testId="tyre-state-select"
        >
          <option v-for="(estado, index) in LIST_ESTADO_PNEUS" :key="index">
            {{ estado }}
          </option>
        </select>
        <p class="form-input-hint">
          Selecione o estado de conservação dos pneus
        </p>
      </div>

      <!-- Tipo do Câmbio -->
      <div
        v-if="!vistoriaSomenteAgregado"
        class="form-group"
        :class="{ 'has-error': v$?.tipoCambio?.$errors.length }"
      >
        <label class="form-label label-sm">Tipo do Câmbio:*</label>
        <select
          class="form-select"
          v-model="state.tipoCambio"
          data-testId="exchange-type-select"
        >
          <option v-for="(cambio, index) in LIST_TIPO_CAMBIO" :key="index">
            {{ cambio }}
          </option>
        </select>
        <p class="form-input-hint">Selecione o tipo do câmbio</p>
      </div>

      <!-- Número do Motor -->
      <div class="form-group pb-2" v-if="!vistoriaSomenteAgregado">
        <label class="form-label label-sm" for="input-numeroMotor"
          >Número do Motor</label
        >
        <input
          class="form-input"
          type="text"
          id="input-numeroMotor"
          placeholder="Informe o número do motor"
          v-model="state.numeroMotor"
          data-testId="motor-number-input"
        />
      </div>
    </div>

    <div class="btn-vistoria container__next">
      <button
        class="btn btn-primary"
        @click="nextStep()"
        :disabled="v$.$invalid"
        data-testId="next-button"
      >
        <IconCheck />
        <span>Salvar Dados</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.veiculo__container {
  margin: 0.8rem auto;

  span {
    display: block;
  }

  &__form {
    margin-top: 0.8rem;
    margin-bottom: 1.6rem;

    input {
      text-transform: uppercase;
    }

    input::placeholder {
      text-transform: none;
    }

    .form-input-hint {
      display: none;
    }

    .has-error {
      .form-input-hint {
        display: block;
      }
      p.form-input-hint {
        margin-bottom: 0;
      }
    }
  }
}
</style>
