import { db } from '../db';
import { updateUserPermissionsStore } from '../store';

/**
 * Salva as permissões do usuário
 * @param value dados
 */
export async function saveUserPermissions(value: any) {
  await db.userPermissions.clear();
  await db.userPermissions.add(value);

  await updateUserPermissionsStore();
}

/**
 * Recupera as permissões do usuário
 */
export async function getsaveUserPermissionsIndexedDB() {
  const [userPermissions] = await db.userPermissions.toArray();

  return userPermissions;
}
