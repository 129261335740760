import { createRouter, createWebHistory } from 'vue-router';

// Component
import AssinaturaView from '@/views/Assinatura/AssinaturaView.vue';
import ConfirmPendencia from '@/views/Pendencia/ConfirmPendencia.vue';
import EnvioVistoriaView from '@/views/Envio/EnvioVistoriaView.vue';
import HomeView from '../views/Home/HomeView.vue';
import PermissaoView from '@/views/Permissao/PermissaoView.vue';
import PhotoView from '@/views/Camera/PhotoView.vue';
import RecoverView from '@/views/Recover/RecoverView.vue';
import ResultadoViewVue from '@/views/Resultado/ResultadoView.vue';
import ResumoView from '@/views/Resumo/ResumoView.vue';
import VerificarcaoView from '@/views/Verificacao/VerificacaoView.vue';
import VideoView from '@/views/Camera/VideoView.vue';
import VistoriaView from '@/views/Vistoria/VistoriaView.vue';

// Service
import { useStepVistoria } from '@/stores/stepVistoria';

// Other
import { ROTA_RESUMO, STATUS_PROPOSTA_AGUARDANDO_APROVACAO } from '@/global';

// Guard
import {
  checkAndGoPermissoes,
  checkAndGoVerificar,
  checkStoreLoad,
} from './guard';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/recover',
      name: 'recover',
      component: RecoverView,
    },
    {
      path: '/:chave?',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/:chave/permissao',
      name: 'permissoes',
      component: PermissaoView,
    },
    {
      path: '/:chave/vistoria',
      name: 'vistoria',
      component: VistoriaView,
      beforeEnter: (options) => {
        const { chave } = options.params;

        const vistoriaStore = useStepVistoria();
        const vistoriaEtapa = vistoriaStore.currentVistoriaEtapa;

        if (!Array.isArray(chave) && vistoriaEtapa) {
          if (checkAndGoPermissoes(chave)) {
            checkAndGoVerificar(chave, vistoriaEtapa);
          }
        }
      },
    },
    {
      path: '/:chave/vistoria/foto',
      name: 'foto',
      component: PhotoView,
    },
    {
      path: '/:chave/vistoria/video',
      name: 'video',
      component: VideoView,
    },
    {
      path: '/:chave/vistoria/verificar',
      name: 'verificar',
      component: VerificarcaoView,
    },
    {
      path: '/:chave/resumo',
      name: 'resumo',
      component: ResumoView,
    },
    {
      path: '/:chave/assinatura',
      name: 'assinatura',
      component: AssinaturaView,
    },
    {
      path: '/:chave/envio',
      name: 'envio-vistoria',
      component: EnvioVistoriaView,
    },
    {
      path: '/:chave/resultado',
      name: 'resultado',
      component: ResultadoViewVue,
      beforeEnter: (options) => {
        const vistoriaStore = useStepVistoria();
        const vistoria = vistoriaStore.vistoria;

        if (vistoria?.id_Status !== STATUS_PROPOSTA_AGUARDANDO_APROVACAO) {
          if (!vistoriaStore.doneDataSync) {
            const { chave } = options.params;
            router.replace({ name: ROTA_RESUMO, params: { chave } });
          }
        }
      },
    },
    {
      path: '/:chave/confirma-pendencia',
      name: 'confirmar-pendencia',
      component: ConfirmPendencia,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const { chave } = to.params;

  const freeRoutes = ['home', 'resultado', 'recover', 'resumo'];
  const allowed = freeRoutes.includes(to.name as string);

  if (!checkStoreLoad() && !allowed) {
    next({ path: `/${chave}`, replace: true });
    return;
  }

  next();
});

export default router;
