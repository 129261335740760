<script setup lang="ts">
const props = defineProps({
  fileContent: String,
});
</script>

<template>
  <div class="preview-pdf">
    <img src="@/assets/gif/pdf.gif" />
    <a :href="props.fileContent" target="_blank" rel="noopener noreferrer"
      >ver Arquivo</a
    >
  </div>
  <slot></slot>
</template>
