<script setup lang="ts">
//#region import's

import { onMounted, ref } from 'vue';

// Component
import ArrowTopRight from '@/shared/icons/ArrowTopRight.vue';
import StatusFail from '@/shared/component/status-fail.vue';

// Service
import { getListEtapasPendentes } from '@/service/vistoria-etapas/index';
import { useStepVistoria } from '@/stores/stepVistoria';

// Other
import {
  goEnvioVistoria,
  goResumo,
  goVistoria,
} from '@/shared/utils/routeNavigate';

//#endregion

const vistoriaStore = useStepVistoria();
const vistoria = vistoriaStore.vistoria;

const listPendencia = ref<{ nomeEtapa: string; idEtapa: string }[]>([]);

const goNavigateVistoria = (idEtapa: string) => {
  vistoriaStore.setCurrentVistoriaEtapaRetry(idEtapa);
  goVistoria();
};

const goNavigateEnvio = () => {
  goEnvioVistoria();
};

const goNavigateResumo = () => {
  goResumo();
};

onMounted(async () => {
  listPendencia.value = await getListEtapasPendentes(vistoria!.chave);
});
</script>

<template>
  <div class="confirmar-pendencia container">
    <StatusFail />

    <div class="confirmar-pendencia__center">
      <span class="title"
        >Atenção, existem etapas a serem concluídas antes do envio</span
      >
    </div>

    <div>
      <ul>
        <li v-for="(element, index) in listPendencia" :key="index">
          <span
            class="link"
            @click="goNavigateVistoria(element.idEtapa)"
            data-testId="link"
          >
            {{ element.nomeEtapa }}
            <ArrowTopRight />
          </span>
        </li>
      </ul>
    </div>

    <div class="confirmar-pendencia__center">
      <p>Você deseja salvar a vistoria com pendências?</p>
    </div>

    <div class="confirmar-pendencia__action">
      <div class="btn-vistoria">
        <button
          class="btn btn-primary"
          @click="goNavigateEnvio()"
          data-testId="yes-button"
        >
          Sim
        </button>
      </div>
      <div class="btn-vistoria">
        <button
          class="btn btn-link"
          @click="goNavigateResumo()"
          data-testId="no-button"
        >
          Não
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.confirmar-pendencia {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  justify-content: space-around;

  svg {
    width: 0.8rem;
    height: 0.8rem;
    display: inline;
  }

  .link {
    cursor: pointer;
  }

  &__center {
    text-align: center;
  }

  &__action {
    display: flex;
    width: 80%;
    gap: 0.6rem;

    .btn-vistoria {
      width: 100%;
    }
  }
}
</style>
