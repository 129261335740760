<script setup lang="ts">
defineProps<{
  show: boolean;
  title?: string;
  message?: any;
}>();

const emit = defineEmits(['cancel', 'confirm']);

const cancelModal = () => {
  emit('cancel');
};

const confirmModal = () => {
  emit('confirm');
};
</script>

<template>
  <div class="modal modal-sm active" v-if="show">
    <div class="modal-overlay" @click="cancelModal()"></div>
    <div class="modal-container">
      <div class="modal-header">
        <div class="modal-title h5">
          {{ title }}
        </div>
      </div>
      <div class="modal-body">
        <span class="texto">
          {{ message }}
        </span>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-link mr-2"
          @click="cancelModal()"
          data-testId="cancel-modal-button"
        >
          Cancelar
        </button>
        <button
          class="btn btn-primary"
          @click="confirmModal()"
          data-testId="confirm-modal-button"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  .modal-header {
    padding: 0.8rem 0.4rem 0.4rem;
  }
  .modal-body {
    padding: 0.4rem;
  }
}
</style>
