import { v4 as uuidv4 } from 'uuid';

// Model
import type { Vistoria } from './../../model/vistoria.interface';
import type {
  VistoriaEtapaDadosImplementoAgregado,
  VistoriaEtapas,
} from '@/model/vistoria-etapas.interface';

// Service
import { db } from '../db';
import { updateVistoriaEtapasStore } from '@/service/store';

// Other
import {
  STATUS_PERMITE_EDICAO_DADOS,
  TIPO_ETAPA_DADOS_AGREGADO,
  TIPO_ETAPA_DADOS_IMPLEMENTO,
} from './../../global';

const PREFIXO_NOME_ETAPA_IMPLEMENTO = 'Dados do Implemento';
const PREFIXO_NOME_ETAPA_AGREGADO = 'Dados do Agregado';

const getNomeEtapa = (prefixo: string, descricao: string, marca: string) => {
  return `${prefixo} - ${descricao} ${marca}`;
};

const getEtapaVistoriaImplemento = (vistoria: Vistoria, ordem: number) => {
  const result: VistoriaEtapas[] = [];
  const listImplementos = vistoria.implemento;

  if (!listImplementos) {
    return [];
  }

  listImplementos.forEach((element: VistoriaEtapaDadosImplementoAgregado) => {
    const nomeEtapa = getNomeEtapa(
      PREFIXO_NOME_ETAPA_IMPLEMENTO,
      element.descricao,
      element.marca,
    );
    const sync = element.validatedForm;

    const etapa: VistoriaEtapas = {
      idEtapa: uuidv4(),
      chave: vistoria.chave,
      ordem,
      nomeEtapa,
      obrigatorio: true,
      tipo: TIPO_ETAPA_DADOS_IMPLEMENTO,
      sync,
      redo: false,
      dadosImplemento: element,
    };

    result.push(etapa);
    ordem++;
  });

  return result;
};

const getEtapaVistoriaAgregado = (vistoria: Vistoria, ordem: number) => {
  const result: VistoriaEtapas[] = [];
  const listAgregados = vistoria.agregado;

  if (!listAgregados) {
    return [];
  }

  listAgregados.forEach((element: VistoriaEtapaDadosImplementoAgregado) => {
    const nomeEtapa = getNomeEtapa(
      PREFIXO_NOME_ETAPA_AGREGADO,
      element.descricao,
      element.marca,
    );
    const sync = element.validatedForm;

    const etapa: VistoriaEtapas = {
      idEtapa: uuidv4(),
      chave: vistoria.chave,
      ordem,
      nomeEtapa,
      obrigatorio: true,
      tipo: TIPO_ETAPA_DADOS_AGREGADO,
      sync,
      redo: false,
      dadosAgregado: element,
    };

    result.push(etapa);
    ordem++;
  });

  return result;
};

/**
 * Recupera as Etapas Dados Implemento/Agregado para salvar no IndexedDB
 * @param vistoria dados da vistoria
 * @param ordem ordem da vistoria
 */
export function getVistoriaEtapasDadosImplementoAgregadoSaveIndexedDB(
  vistoria: Vistoria,
  ordem: number,
) {
  const idStatus = vistoria.id_Status;

  if (!STATUS_PERMITE_EDICAO_DADOS.includes(idStatus)) {
    return null;
  }

  if (!vistoria.implemento && !vistoria.agregado) {
    return null;
  }

  const listImplemento = getEtapaVistoriaImplemento(vistoria, ordem);
  ordem += listImplemento.length;

  const listAgregado = getEtapaVistoriaAgregado(vistoria, ordem);

  return [...listImplemento, ...listAgregado];
}

/**
 * Salvar os dados do Implemento/Agregado
 * @param chave chave da proposta/vistoria
 * @param idEtapa id da etapa da vistoria
 * @param dados dados do implemento/agregado
 */
export async function saveDadosImplementoAgregado(
  chave: string,
  idEtapa: string,
  dados: VistoriaEtapaDadosImplementoAgregado,
  tipo: 'DADOS_IMPLEMENTO' | 'DADOS_AGREGADO',
) {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('[idEtapa+chave]')
    .equals([idEtapa, chave])
    .first();

  // Sanitize
  dados.placa = dados.placa?.toUpperCase();
  dados.chassi = dados.chassi?.toUpperCase();

  if (vistoriaEtapas) {
    if (tipo === TIPO_ETAPA_DADOS_IMPLEMENTO) {
      vistoriaEtapas.nomeEtapa = getNomeEtapa(
        PREFIXO_NOME_ETAPA_IMPLEMENTO,
        dados.descricao,
        dados.marca,
      );
      vistoriaEtapas!.dadosImplemento = dados;
    }

    if (tipo === TIPO_ETAPA_DADOS_AGREGADO) {
      vistoriaEtapas.nomeEtapa = getNomeEtapa(
        PREFIXO_NOME_ETAPA_AGREGADO,
        dados.descricao,
        dados.marca,
      );
      vistoriaEtapas!.dadosAgregado = dados;
    }

    vistoriaEtapas!.prepared = true;
    vistoriaEtapas!.sync = false;

    await db.vistoriaEtapas.update([idEtapa, chave], vistoriaEtapas);

    await updateVistoriaEtapasStore(chave);
  }
}

/**
 * Recupera as Etapas Dados do Implemento/Agregado não sincronizada
 * @param chave da proposta/vistoria
 */
export async function getVistoriaEtapasDadosImplementoAgregadoNotSync(
  chave: string,
) {
  const result: VistoriaEtapas[] = [];

  const vistoriaEtapas = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .toArray();

  const vistoriaEtapa = vistoriaEtapas.filter((x) =>
    [TIPO_ETAPA_DADOS_IMPLEMENTO, TIPO_ETAPA_DADOS_AGREGADO].includes(x.tipo),
  );

  vistoriaEtapa.forEach((element) => {
    if (!element.sync && element.prepared) {
      result.push(element);
    }
  });

  return result;
}
