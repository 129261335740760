<script lang="ts" setup>
//#region Import

import { onMounted, ref } from 'vue';

// Component
import IconCamera from '@/shared/icons/IconCamera.vue';
import ModalBadFile from '@/shared/component/modal-bad-file.vue';
import ModalWaitSaveFile from '@/shared/component/modal-wait-save-file.vue';

// Service
import { insertEtapaFotoVideoAdicional } from '@/service/vistoria-etapas';
import { saveFileBase64 } from '@/service/vistoria-files';
import { useStepVistoria } from '@/stores/stepVistoria';

// Other
import { ID_MODELO_FOTO_ADICIONAL, TIPO_ETAPA_FOTO } from '@/global';
import { convertArrayBufferToBase64 } from '@/shared/utils/convert';
import { goVerificar } from '@/shared/utils/routeNavigate';
import { resizeImage } from '@/service/files/utils';

//#endregion

const vistoriaStore = useStepVistoria();

const inputElement = ref<HTMLInputElement>();
const showModal = ref<boolean>(false);
const showModalLoading = ref<boolean>(false);
const accept = ref<string>('image/*');

/**
 * Fecha o modal
 */
const closeModal = () => {
  showModal.value = false;
};

/**
 * Validar o tipo de arquivo
 * @param type tipo do arquivo
 */
const validateFileType = (type: string) => {
  if (type.indexOf('image/') >= 0) {
    return true;
  }

  if (type.indexOf('application/pdf') >= 0) {
    return true;
  }

  showModal.value = true;
  return false;
};

/**
 * Arquivo selecionado através da galeria
 */
const selectedFile = async () => {
  if (inputElement.value?.files) {
    const file: File = inputElement.value.files[0];

    if (file) {
      if (!validateFileType(file.type)) {
        return;
      }

      showModalLoading.value = true;

      if (props.fotoAdicional) {
        await addFotoAdicional();
      }

      showModalLoading.value = true;

      let fileBase64: string;
      const type = file.type;

      if (type.indexOf('image/') >= 0) {
        fileBase64 = await resizeImage(file, 1600);
      } else {
        const buffer = await file.arrayBuffer();
        fileBase64 = await convertArrayBufferToBase64(buffer, type);
      }

      await saveFileBase64(
        vistoriaStore.currentVistoriaEtapa!.idEtapa,
        vistoriaStore.vistoria!.chave,
        fileBase64,
        type,
      );

      goVerificar();
    }
  }
};

const props = defineProps({
  fotoAdicional: {
    type: Boolean,
    default: false,
  },
});

/**
 * Adicona Foto Adicional
 */
const addFotoAdicional = async () => {
  const idEtapa = await insertEtapaFotoVideoAdicional(
    vistoriaStore.vistoria!.chave,
    ID_MODELO_FOTO_ADICIONAL,
  );
  vistoriaStore.setCurrentVistoriaEtapaRetry(idEtapa);
};

onMounted(() => {
  if (props.fotoAdicional) {
    accept.value = 'image/*, application/pdf';
  }
});
</script>

<template>
  <div>
    <div
      v-if="
        vistoriaStore.currentVistoriaEtapa?.tipo === TIPO_ETAPA_FOTO ||
        props.fotoAdicional
      "
      :class="{
        'card buttonTakeGalleryFile': !props.fotoAdicional,
        'btn-vistoria': props.fotoAdicional,
      }"
      role="button"
    >
      <label for="input-file" v-if="!props.fotoAdicional">
        <span>Tirar Foto</span>
        <IconCamera />
      </label>
      <label for="input-file" v-else class="btn">
        Incluir Foto Adicional
      </label>
      <input
        type="file"
        id="input-file"
        style="display: none"
        :accept="accept"
        ref="inputElement"
        capture="environment"
        @change="selectedFile()"
      />
    </div>

    <ModalBadFile :show="showModal" @close="closeModal()" />

    <ModalWaitSaveFile :show="showModalLoading" />
  </div>
</template>

<style lang="scss">
.buttonTakeGalleryFile {
  margin-top: 0.8rem;
  color: var(--primary);

  label {
    display: flex;
    align-items: center;
    flex-direction: row !important;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
  }

  span {
    font-weight: 500;
    display: block;
  }

  svg {
    display: block;
    width: 2rem;
    height: 2rem;
  }
}

.btn-vistoria {
  margin-top: 0.8rem;

  label {
    width: 100%;
    text-transform: uppercase;
    font-size: 0.7rem;
  }
}
</style>
