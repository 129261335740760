<script setup lang="ts">
//#region Import

import { onMounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';

// Component
import IconCheck from '@/shared/icons/IconCheck.vue';
import IconExclamation from '@/shared/icons/IconExclamation.vue';
import Loading from '@/shared/component/show-loading.vue';

// Model
import type { CheckListPermissions } from './check-list';
import type { UserPermissions } from '@/model/permissions-user.interface';

// Service
import { goResumo } from '@/shared/utils/routeNavigate';
import { savePermissions } from '@/service/vistoria';
import { saveUserPermissions } from '@/service/permissions';

// Other
import { CHECK_LIST_PERMISSIONS } from './check-list';

//#endregion

const route = useRoute();
const checkListPermissions = reactive(CHECK_LIST_PERMISSIONS);
const loading = ref<boolean>(true);

/**
 * Salva as permissões e avança para a vistoria
 */
const saveAndGoVistoria = async () => {
  const { chave: chaveParams } = route.params;

  const chave = chaveParams as string;
  await savePermissions(chave);

  goResumo(true);
};

/**
 * Executa a função do item do check
 * @param element item do check
 */
const proxyExecFunction = async (element: CheckListPermissions) => {
  await element.functionName(element);

  const allAllowPermissions = checkListPermissions.every(
    (v) => v.allowPermission !== null,
  );

  if (allAllowPermissions) {
    const permissions: any = {};

    checkListPermissions.forEach((element) => {
      permissions[element.permissions] = element.allowPermission;
    });

    await saveUserPermissions(permissions as UserPermissions);

    await saveAndGoVistoria();
  }
};

/**
 * Reiniciar a lista de checagens de permissões
 */
const resetPermissions = () => {
  checkListPermissions.forEach((element) => {
    element.checkPermission = true;
    element.allowPermission = null;
  });
};

onMounted(async () => {
  setTimeout(() => {
    resetPermissions();
    loading.value = !loading.value;
  }, 1000);
});
</script>

<template>
  <Loading :show="loading" />

  <div class="permissao container" v-if="!loading">
    <h1 class="title">Mas antes...</h1>
    <p class="texto">Precisamos da sua permissão para acessar:</p>

    <div
      class="card"
      v-for="(element, index) of checkListPermissions"
      :key="index"
    >
      <div class="card-header">
        <div class="card-title title">
          <component :is="element.iconComponent" :key="index" />
          <span>{{ element.title }}</span>
        </div>
      </div>
      <div class="card-body">
        <p class="texto">{{ element.label }}</p>
        <div class="text-right">
          <button
            class="btn btn-primary"
            @click="proxyExecFunction(element)"
            v-if="element.checkPermission"
            :disabled="element.processing"
            :data-testId="`approve-permission-button-${index}`"
          >
            Permitir
          </button>
        </div>
        <progress
          v-if="element.processing"
          class="progress"
          max="100"
        ></progress>

        <!-- Permissão Permitida -->
        <div class="permissao__aceita" v-if="element.allowPermission">
          <IconCheck />
          <span class="texto">{{ element.labelSuccess }}</span>
        </div>

        <!-- Permissão Negada -->
        <div class="permissao__negada" v-if="element.allowPermission === false">
          <IconExclamation />
          <span class="texto">{{ element.labelFail }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.permissao {
  margin-top: 1.6rem;

  .card {
    margin-bottom: 1.2rem;
    .card-title {
      display: flex;
      align-items: center;
      gap: 0.4rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }

      span {
        display: block;
      }
    }
  }

  &__aceita,
  &__negada {
    display: flex;
    align-items: center;
    gap: 0.4rem;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    .texto {
      color: green;
    }
  }

  &__negada {
    .texto {
      color: #a90000;
    }
  }
}
</style>
