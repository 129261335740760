<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M2435 4900 c-253 -30 -455 -121 -668 -302 -177 -150 -259 -183 -512
-203 -190 -16 -278 -37 -402 -95 -270 -126 -458 -346 -550 -645 l-28 -90 0
-1020 c0 -1049 2 -1128 41 -1340 31 -168 105 -357 178 -455 l26 -34 -114 -116
c-62 -63 -119 -125 -125 -137 -6 -12 -11 -43 -11 -70 0 -90 63 -153 153 -153
26 0 60 6 75 14 33 18 4325 4312 4341 4343 17 33 13 104 -8 143 -34 65 -125
97 -198 70 -15 -6 -129 -112 -253 -236 l-226 -225 -64 16 c-36 9 -137 22 -225
29 -252 22 -340 58 -529 218 -216 184 -454 279 -731 292 -55 2 -131 1 -170 -4z
m334 -341 c121 -30 262 -106 370 -199 121 -104 163 -133 276 -186 115 -55 222
-83 340 -91 50 -3 96 -9 102 -12 8 -4 -145 -165 -410 -429 l-423 -423 -71 30
c-140 58 -233 75 -393 75 -165 -1 -258 -19 -397 -77 -248 -105 -460 -327 -552
-579 -49 -132 -56 -176 -55 -358 0 -161 3 -182 28 -265 15 -49 38 -113 50
-141 l24 -51 -454 -454 c-370 -370 -456 -451 -465 -439 -26 37 -70 145 -88
217 -52 202 -54 238 -58 1301 -4 969 -4 994 16 1072 51 202 180 363 365 454
92 45 169 62 341 75 169 12 266 36 390 95 109 51 161 85 259 170 86 75 141
113 221 154 176 88 388 110 584 61z m-46 -1569 c26 -7 47 -15 47 -19 0 -3
-195 -201 -434 -440 l-434 -434 -14 44 c-8 27 -13 95 -13 179 0 125 3 141 31
221 85 240 289 413 539 459 62 11 214 6 278 -10z"
      />
      <path
        d="M4570 3868 c-52 -26 -85 -71 -93 -125 -6 -39 -5 -48 32 -187 21 -78
21 -98 21 -975 0 -509 -5 -959 -10 -1041 -20 -275 -59 -444 -131 -567 -46 -78
-199 -229 -279 -276 -120 -69 -283 -108 -540 -127 -195 -14 -1848 -13 -2025 2
-190 16 -244 15 -282 -5 -78 -41 -114 -144 -76 -217 28 -55 80 -75 235 -91
188 -20 2096 -20 2273 -1 226 25 379 64 519 133 101 50 162 95 264 194 210
202 303 409 349 777 16 129 18 242 18 1173 l0 1030 -28 90 c-45 144 -72 194
-121 216 -53 24 -72 24 -126 -3z"
      />
      <path
        d="M3345 2641 c-89 -41 -107 -88 -98 -264 7 -164 -9 -249 -72 -375 -89
-177 -244 -303 -440 -358 -45 -12 -104 -17 -236 -18 l-176 -1 -36 -28 c-48
-37 -67 -75 -67 -136 0 -110 96 -155 335 -155 136 -1 222 14 345 56 248 87
463 281 575 521 60 126 86 236 92 382 9 212 -18 327 -86 372 -41 28 -82 29
-136 4z"
      />
    </g>
  </svg>
</template>
