import { v4 as uuidv4 } from 'uuid';
import { db } from '../db';

// Model
import type { VistoriaEtapas } from '@/model/vistoria-etapas.interface';
import type { VistoriaFilesAndData } from '@/model/vistoria-files-data.interface';

// Service / Store / Utils
import {
  convertArrayBufferToBlob,
  convertBase64ToArrayBuffer,
  convertBlobToArrayBuffer,
} from '@/shared/utils/convert';
import { getFileCache, saveFileIndexDb } from '../files';
import { updateVistoriaEtapasStore } from '@/service/store';

// Other
import { getCurrentPosition } from '@/shared/utils/getCurrentPosition';

/**
 * Salva os dados da Foto/Video
 * @param idEtapa id da etapa da vistoria
 * @param chave chava da proposta
 * @param nomeArquivo nome doa rquivo
 * @param idFotoAdicional id da foto adicional
 */
const saveVistoriaEtapasFotoVideo = async (
  idEtapa: string,
  chave: string,
  nomeArquivo: string,
  idFotoAdicional?: string,
) => {
  const vistoriaEtapa = await db.vistoriaEtapas
    .where('[idEtapa+chave]')
    .equals([idEtapa, chave])
    .first();

  if (vistoriaEtapa) {
    const location = await getCurrentPosition();
    const { latitude, longitude } = location.coords;

    vistoriaEtapa!.fotoVideo!.nome = { id: nomeArquivo };
    vistoriaEtapa!.fotoVideo!.latitude = latitude;
    vistoriaEtapa!.fotoVideo!.longitude = longitude;
    vistoriaEtapa!.fotoVideo!.capturedAt = new Date();
    vistoriaEtapa!.fotoVideo!.aprovada = null;
    vistoriaEtapa!.fotoVideo!.nome.url = undefined;
    vistoriaEtapa!.redo = false;
    vistoriaEtapa!.sync = false;
    vistoriaEtapa!.prepared = true;

    if (idFotoAdicional) {
      vistoriaEtapa!.fotoVideo!.idFotoAdicional = idFotoAdicional;
    }

    await db.vistoriaEtapas.update([idEtapa, chave], vistoriaEtapa);
    await updateVistoriaEtapasStore(chave);
  }
};

/**
 * Salva arquivo no formatao base 64
 * @param idEtapa id da etapa da vistoria
 * @param chave chave da proposta/vistoria
 * @param arquivoBase64 arquivo em base64
 */
export async function saveFileBase64(
  idEtapa: string,
  chave: string,
  arquivoBase64: string,
  type: string = 'image/jpeg',
  idFotoAdicional?: string,
) {
  const nome = uuidv4();
  const imagemArrayBuffer = await convertBase64ToArrayBuffer(arquivoBase64);

  await saveFileIndexDb(imagemArrayBuffer, type, nome);

  await deleteFile(idEtapa, chave);

  await saveVistoriaEtapasFotoVideo(idEtapa, chave, nome, idFotoAdicional);
}

/**
 * Salva o video
 * @param idEtapa id da etapa da vistoria
 * @param chave chave da proposta/vistoria
 * @param blobsRecorded video gravado
 */
export async function saveVideo(
  idEtapa: string,
  chave: string,
  blobsRecorded: Blob[],
  type: string,
  idFotoAdicional?: string,
) {
  const nome = uuidv4();
  const videoArrayBuffer = await convertBlobToArrayBuffer(
    new Blob(blobsRecorded, { type }),
  );

  await saveFileIndexDb(videoArrayBuffer, type, nome);

  await deleteFile(idEtapa, chave);

  await saveVistoriaEtapasFotoVideo(idEtapa, chave, nome, idFotoAdicional);
}

/**
 * Recupera a observações da etapa
 * @param idEtapa id da etapa da vistoria
 */
export async function getObservation(
  idEtapa: string,
): Promise<{ observacoes?: string } | null> {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('idEtapa')
    .equals(idEtapa)
    .first();

  const idFile = vistoriaEtapas?.fotoVideo?.nome?.id;
  const observacoes = vistoriaEtapas?.fotoVideo?.observacaoVistoria;

  if (idFile)
    return {
      observacoes,
    };

  return null;
}

/**
 * Recupera a Foto/Video no IndexDB
 * @param idEtapa id da etapa da vistoria
 * @returns base64 da imagem
 */
export async function getFileAndObservation(idEtapa: string): Promise<{
  nome: string;
  fileContent: string;
  type: string;
  observacoes?: string;
} | null> {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('idEtapa')
    .equals(idEtapa)
    .first();

  const idFile = vistoriaEtapas?.fotoVideo?.nome?.id;
  const observacoes = vistoriaEtapas?.fotoVideo?.observacaoVistoria;

  let fileContent = '';
  let type = 'video/*'; // Valor padrão, por que os vídeos não são baixados

  if (vistoriaEtapas?.fotoVideo?.nome?.url) {
    fileContent = vistoriaEtapas?.fotoVideo?.nome?.url;
  }

  if (!idFile) {
    return null;
  }

  const files = await getFileCache(idFile);

  if (files) {
    fileContent = files.fileContent;
    type = files.type;
  }

  return {
    nome: idFile,
    fileContent,
    type,
    observacoes,
  };
}

/**
 * Salva as observações da foto
 * @param nome do arquivo
 * @param observacao Observação da foto
 * @param chave da proposta/vistoria
 * @param idEtapa id da etapa da vistoria
 */
export async function saveObservation(
  chave: string,
  idEtapa: string,
  observacaoVistoria: string,
) {
  const vistoriaEtapa = await db.vistoriaEtapas
    .where('[idEtapa+chave]')
    .equals([idEtapa, chave])
    .first();

  if (vistoriaEtapa) {
    vistoriaEtapa.fotoVideo!.observacaoVistoria = observacaoVistoria;

    await db.vistoriaEtapas.update([idEtapa, chave], vistoriaEtapa);
  }
}

/**
 * Excluir o arquivo
 * @param idEtapa id da etapa da vistoria
 * @param chave chave da proposta/vistoria
 */
export async function deleteFile(idEtapa: string, chave: string) {
  const vistoriaEtapa = await db.vistoriaEtapas
    .where(['idEtapa+chave'])
    .equals([idEtapa, chave])
    .first();

  if (vistoriaEtapa) {
    const idFile = vistoriaEtapa.fotoVideo!.nome?.id;
    vistoriaEtapa.fotoVideo!.nome = undefined;

    await db.vistoriaEtapas.update([idEtapa, chave], vistoriaEtapa);
    await updateVistoriaEtapasStore(chave);

    if (idFile) {
      await db.files.where('id').equals(idFile).delete();
    }
  }
}

/**
 * Recupera os arquivos da vistoria
 * @param chave chave da proposta/vistoria
 * @returns
 */
export async function getVistoriaFiles(chave: string) {
  const result: VistoriaFilesAndData[] = [];

  const vistoriaEtapas = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .toArray();

  const vistoria = await db.vistoria.where('chave').equals(chave).first();
  const idProposta = vistoria!.idProposta;

  for (let index = 0; index < vistoriaEtapas.length; index++) {
    const element = vistoriaEtapas[index];

    if (element.fotoVideo?.nome?.id && !element.sync) {
      const idFile = element.fotoVideo.nome.id;

      const file = await db.files.where('id').equals(idFile).first();
      const blob = convertArrayBufferToBlob(file!.content, file!.type);

      const item: VistoriaFilesAndData = {
        idEtapa: element.idEtapa,
        chave: element.chave,
        contentFile: blob,
        name: idFile,
        size: blob.size,
        tipo: element.tipo,
        data: {
          idProposta,
          tipo: element.fotoVideo.tipoFoto,
          idModeloFoto: element.fotoVideo.idModeloFoto,
          latitude: element.fotoVideo.latitude?.toString() || null,
          longitude: element.fotoVideo.longitude?.toString() || null,
          dadosCelular: navigator.userAgent,
          observacaoVistoria: element.fotoVideo.observacaoVistoria,
          capturedAt: element.fotoVideo.capturedAt!.toISOString(),
          idFotoAdicional: element.fotoVideo?.idFotoAdicional,
        },
      };

      result.push(item);
    }
  }

  return result.sort((a, b) => (a.size > b.size ? 1 : -1));
}

/**
 * Atualiza a URL do arquivo no S3
 * @param idEtapa id etapa da vistoria
 * @param chave da proposta/vistoria
 * @param url url do arquivo no s3
 */
export async function updateVistoriaEtapaS3(
  idEtapa: string,
  chave: string,
  url: string,
) {
  const vistoriaEtapa = await db.vistoriaEtapas
    .where('[idEtapa+chave]')
    .equals([idEtapa, chave])
    .first();

  if (vistoriaEtapa) {
    vistoriaEtapa.fotoVideo!.nome!.url = url;
    vistoriaEtapa.sync = true;
    vistoriaEtapa.prepared = false;

    await db.vistoriaEtapas.update([idEtapa, chave], vistoriaEtapa);
    await updateVistoriaEtapasStore(chave);
  }
}

/**
 * Sinaliza etapas já sincronizadas
 * @param chave da proposta/vistoria
 * @param vistoriaEtapas array de etapas
 */
export async function updateVistoriaEtapaSync(
  chave: string,
  vistoriaEtapas: VistoriaEtapas[],
) {
  for (let index = 0; index < vistoriaEtapas.length; index++) {
    const element = vistoriaEtapas[index];

    await db.vistoriaEtapas.update([element.idEtapa, element.chave], {
      sync: true,
      prepared: false,
    });
  }

  await updateVistoriaEtapasStore(chave);
}
