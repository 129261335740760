<script lang="ts" setup>
//#region Import

// Component
import ButtonTakeSignature from './../component/shared/buttonTakeSignature.vue';

// Service
import { useStepVistoria } from '@/stores/stepVistoria';

//#endregion

const vistoriaStore = useStepVistoria();
</script>

<template>
  <div class="container assinatura__container">
    <span class="title">
      {{ vistoriaStore.currentVistoriaEtapa?.nomeEtapa }}
    </span>

    <div class="assinatura__container__image">
      <img src="@/assets/gif/assinatura.gif" />
    </div>

    <p class="texto">Realize a assinatura utilizando o dedo como caneta!</p>

    <ButtonTakeSignature />
  </div>
</template>

<style lang="scss">
.assinatura__container {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;

  p {
    margin-bottom: 0.4rem;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 15rem;
      width: 100%;
    }
  }
}
</style>
