import axios, { AxiosHeaders } from 'axios';

// Service

import { db } from '@/service/db';

// Model
import type { Files } from './../../model/files.interface';
import type {
  VistoriaEtapaCacheFile,
  VistoriaEtapas,
} from '@/model/vistoria-etapas.interface';

// Other
import { TIPO_ETAPA_VIDEO } from '@/global';

//#region Util's

/**
 * Recuperar o mineType suportado para
 * gravação de video pelo Navegador
 * @returns
 */
export function mimeTypeSuported(): string {
  const types = ['video/webm', 'video/mp4'];

  for (const type of types) {
    if (MediaRecorder.isTypeSupported(type)) {
      return type;
    }
  }

  return '';
}

/**
 * Verifica se o arquivo ja existe no array ou se
 * o download e armazenado no IndexedDB já foi realizado
 * @param array array a ser pesquisado
 * @param id id a ser pesquisado
 */
const checkFileExists = (
  downloadedFiles: Files[],
  array: VistoriaEtapaCacheFile[],
  id: string,
) => {
  const inBase = array.find((x) => x.id === id);

  if (!inBase) {
    const inDownloadedFiles = downloadedFiles.find((x) => x.id === id);

    return inDownloadedFiles ? true : false;
  }

  return true;
};

//#endregion

//#region IndexedDB

/**
 * Recupera o arquivo do IndexedDB
 * @param idFile id do arquivo
 * @returns {Promise}
 */
export async function getFileCache(idFile: string) {
  const files = await db.files.where('id').equals(idFile).first();

  if (!files || !files?.id) {
    return null;
  }

  const blob = new Blob([files.content], { type: files.type });
  const fileContent = URL.createObjectURL(blob);

  // 22/06 - Melhorias na performance
  // let fileContent = await convertArrayBufferToBase64(files.content, files.type);

  // if (files.type === TYPE_MINE_PDF) {
  //   const blob = await convertBase64ToBlob(fileContent);
  //   fileContent = URL.createObjectURL(blob);
  // }

  return {
    id: files.id,
    fileContent,
    type: files.type,
  };
}

/**
 * Recupera listagem de arquivos do IndexedDB
 */
export async function getListFileCache() {
  const result = await db.files.toArray();
  return result;
}

/**
 * Salva Arquivo no IndexedDB
 * @param content conteúdo do arquivo
 * @param type tipo do arquivo
 * @param id nome do arquivo
 */
export async function saveFileIndexDb(
  content: ArrayBuffer,
  type: string,
  id: string,
) {
  await db.files.add({
    content,
    type,
    id,
  });
}

//#endregion

//#region API

/**
 * Realiza download/cache dos arquivos
 * @param vistoriaEtapas etapas da vistoria
 */
export async function cacheFile(
  vistoriaEtapas: VistoriaEtapas[],
): Promise<void> {
  const downloadedFiles = await db.files.toArray();

  return new Promise<void>((resolve, reject) => {
    const base: VistoriaEtapaCacheFile[] = [];

    vistoriaEtapas.forEach((element) => {
      if (element.fotoVideo) {
        // Foto Modelo
        if (element.fotoVideo.fotoPadrao) {
          const { id, url } = element.fotoVideo.fotoPadrao;
          const item = { id, url };
          const find = checkFileExists(downloadedFiles, base, id);

          if (!find) {
            base.push(item);
          }
        }

        // Foto da Vistoria enviada anteriormente
        if (element.fotoVideo.nome && element.tipo !== TIPO_ETAPA_VIDEO) {
          const { id, url } = element.fotoVideo.nome;
          const item = { id, url };
          const find = checkFileExists(downloadedFiles, base, id);

          if (!find) {
            base.push(item);
          }
        }
      }
    });

    Promise.all(
      base.map((element) => {
        return getFileToCache(element);
      }),
    )
      .then(() => {
        resolve();
      })
      .catch((error) => reject(error));
  });
}

/**
 * Realizar download do arquivo
 * @param value dados para realizar o download do arquivo
 * @returns {Promise<void>}
 */
const getFileToCache = (value: VistoriaEtapaCacheFile) => {
  return new Promise<void>((resolve, reject) => {
    if (value.url) {
      axios
        .get(value.url, {
          responseType: 'arraybuffer',
        })
        .then(async (res) => {
          const header = res.headers as AxiosHeaders;
          const type = (header.getContentType() || 'image/jpeg') as string;

          await saveFileIndexDb(res.data, type, value.id);
          resolve();
        })
        .catch((error) => reject(error));
    }
  });
};

//#endregion
