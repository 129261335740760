import router from '@/router';

import {
  ROTA_ASSINATURA,
  ROTA_ENVIO_VISTORIA,
  ROTA_FOTO,
  ROTA_HOME,
  ROTA_RESULTADO,
  ROTA_RESUMO,
  ROTA_VERIFICAR,
  ROTA_VIDEO,
  ROTA_VISTORIA,
} from '@/global';

/**
 * Navega para a rota Vistoria
 */
export function goVistoria(replace?: boolean) {
  const route = { name: ROTA_VISTORIA };

  if (replace) {
    router.replace(route);
  }

  router.push(route);
}

/**
 * Navega para a rota Resumo
 * @param replace deve substituir a rota atual
 */
export function goResumo(replace?: boolean) {
  const route = { name: ROTA_RESUMO };
  if (!replace) {
    router.push(route);
    return;
  }

  router.replace(route);
}

/**
 * Navega para a rota Resultado
 */
export function goResultado() {
  router.replace({ name: ROTA_RESULTADO });
}

/**
 * Navega para a rota Tirar Foto
 */
export function goTakePhoto() {
  router.push({ name: ROTA_FOTO });
}

/**
 * Navega para a rota Gravar vídeo
 */
export function goTakeVideo() {
  router.push({ name: ROTA_VIDEO });
}

/**
 * Navega para a rota de 'Verificar'
 */
export function goVerificar() {
  router.replace({ name: ROTA_VERIFICAR });
}

/**
 * Navega para a rota de 'Assinatura'
 */
export function goAssinatura() {
  router.replace({ name: ROTA_ASSINATURA });
}

/**
 * Navega para a rota 'Home'
 * @param chave chave da proposta/vistoria
 */
export function goHome(chave: string) {
  return router.replace({ name: ROTA_HOME, params: { chave: chave } });
}

/**
 * Navega para a rota 'Envio'
 */
export function goEnvioVistoria(replace?: boolean) {
  const route = { name: ROTA_ENVIO_VISTORIA };

  if (!replace) {
    router.push(route);
  }

  router.replace(route);
}
