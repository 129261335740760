/**
 * Convert base 64 para Blob
 * @param value string basee64 do arquivo
 * @returns
 */
export async function convertBase64ToBlob(value: string): Promise<Blob> {
  const base64Response = await fetch(value);
  const blob = await base64Response.blob();
  return blob;
}

/**
 * Convert Blob para ArrayBuffer
 * @param blob objeto blob do arquivo
 * @returns
 */
export function convertBlobToArrayBuffer(blob: Blob) {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', () => {
      resolve(reader.result as ArrayBuffer);
    });
    reader.addEventListener('error', reject);
    reader.readAsArrayBuffer(blob);
  });
}

/**
 * Convert Base 64 para ArrayBuffer
 * @param value string base64 do arquivo
 * @returns
 */
export async function convertBase64ToArrayBuffer(value: string) {
  const blob = await convertBase64ToBlob(value);
  const result = await convertBlobToArrayBuffer(blob);
  return result;
}

/**
 * Convert ArrayBuffer para Base64
 * @param buffer ArrayBuffer do arquivo
 * @param type type/mine do arquivo
 * @returns
 */
export function convertArrayBufferToBase64(buffer: ArrayBuffer, type: string) {
  return new Promise<string>((resolve) => {
    let blob: Blob | null;
    let reader: FileReader | null;

    blob = new Blob([buffer], { type: type });

    reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = () => {
      const result = reader!.result as string;

      blob = null;
      reader = null;

      resolve(result);
    };
  });
}

/**
 * Convert ArrayBuffer para Blob
 * @param buffer ArrayBuffer do arquivo
 * @param type type/mine do arquivo
 * @returns
 */
export function convertArrayBufferToBlob(buffer: ArrayBuffer, type: string) {
  return new Blob([buffer], { type });
}
