<script lang="ts" setup>
//#region Import

import { ref } from 'vue';

// Component
import IconPhoto from '@/shared/icons/IconPhoto.vue';
import ModalBadFile from '@/shared/component/modal-bad-file.vue';
import ModalWaitSaveFile from '@/shared/component/modal-wait-save-file.vue';

// Service
import { useStepVistoria } from '@/stores/stepVistoria';
import { saveFileBase64 } from '@/service/vistoria-files';

// Other
import { TIPO_ETAPA_FOTO, TIPO_FOTO_DOCUMENTACAO } from '@/global';
import { convertArrayBufferToBase64 } from '@/shared/utils/convert';
import { goVerificar } from '@/shared/utils/routeNavigate';
import { resizeImage } from '@/service/files/utils';

//#endregion

const vistoriaStore = useStepVistoria();

const inputElement = ref<HTMLInputElement>();
const showModal = ref<boolean>(false);
const showModalLoading = ref<boolean>(false);

/**
 * Fecha o modal
 */
const closeModal = () => {
  showModal.value = false;
};

/**
 * Validar o tipo de arquivo
 * @param type tipo do arquivo
 */
const validateFileType = (type: string) => {
  if (type.indexOf('image/') >= 0) {
    return true;
  }

  if (type.indexOf('application/pdf') >= 0) {
    return true;
  }

  showModal.value = true;
  return false;
};

/**
 * Arquivo selecionado através da galeria
 */
const selectedFile = async () => {
  if (inputElement.value?.files) {
    const file: File = inputElement.value.files[0];

    if (file) {
      if (!validateFileType(file.type)) {
        return;
      }

      showModalLoading.value = true;

      let fileBase64: string;
      const type = file.type;

      if (type.indexOf('image/') >= 0) {
        fileBase64 = await resizeImage(file, 1600);
      } else {
        const buffer = await file.arrayBuffer();
        fileBase64 = await convertArrayBufferToBase64(buffer, type);
      }

      await saveFileBase64(
        vistoriaStore.currentVistoriaEtapa!.idEtapa,
        vistoriaStore.vistoria!.chave,
        fileBase64,
        type,
      );

      goVerificar();
    }
  }
};
</script>

<template>
  <div
    class="card buttonTakeGalleryFile"
    role="button"
    v-if="
      vistoriaStore.currentVistoriaEtapa?.tipo === TIPO_ETAPA_FOTO &&
      vistoriaStore.currentVistoriaEtapa.fotoVideo?.tipoFoto ===
        TIPO_FOTO_DOCUMENTACAO
    "
  >
    <label for="input-file-galery">
      <span>Selecionar da Galeria</span>
      <IconPhoto />
    </label>
    <input
      type="file"
      id="input-file-galery"
      style="display: none"
      accept="image/*, application/pdf"
      ref="inputElement"
      @change="selectedFile()"
    />
  </div>

  <ModalBadFile :show="showModal" @close="closeModal()" />

  <ModalWaitSaveFile :show="showModalLoading" />
</template>

<style lang="scss">
.buttonTakeGalleryFile {
  margin-top: 0.8rem;
  color: var(--primary);

  label {
    display: flex;
    align-items: center;
    flex-direction: row !important;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
  }

  span {
    font-weight: 500;
    display: block;
  }

  svg {
    display: block;
    width: 2rem;
    height: 2rem;
  }
}
</style>
