import { v4 as uuidv4 } from 'uuid';

// Model
import type { Vistoria } from '@/model/vistoria.interface';
import type { VistoriaEtapas } from '@/model/vistoria-etapas.interface';

// Service
import { db } from './../db';
import { updateVistoriaEtapasStore } from '@/service/store';

// Other
import { TIPO_ETAPA_TERMO, TITULO_POR_TIPO_CONTRATO } from '@/global';

//#region Private Functions

/**
 * Recupra o Título do Contrato
 * @param tipoContrato tipo de Contrato
 * @returns
 */
const getTituloContrato = (tipoContrato: string) => {
  return (
    TITULO_POR_TIPO_CONTRATO.find((x) => x.tipoContrato === tipoContrato)
      ?.tituloContrato || ''
  );
};

//#endregion

/**
 * Recupera as Etapas Termos para salvar no IndexedDB
 * @param vistoria dados da vistoria
 * @param ordem ordem da vistoria
 */
export function getVistoriaEtapasTermosSaveIndexedDB(
  vistoria: Vistoria,
  ordem: number,
) {
  const chave = vistoria.chave;
  const contratos = vistoria.contratos || [];
  const result: VistoriaEtapas[] = [];

  contratos.forEach((element) => {
    let dataHoraAceite!: Date;
    let aceite!: boolean;
    let sync = false;

    if (element?.aceite) {
      dataHoraAceite = element.aceite;
      aceite = true;
      sync = true;
    }

    result.push({
      idEtapa: uuidv4(),
      chave,
      ordem,
      nomeEtapa: getTituloContrato(element.tipoContrato),
      obrigatorio: true,
      tipo: TIPO_ETAPA_TERMO,
      sync,
      contrato: {
        idModelo: element.idModelo,
        modeloContrato: element.modeloContrato,
        tipoContrato: element.tipoContrato,
        tituloContrato: getTituloContrato(element.tipoContrato),
        dataHoraAceite,
        aceite,
      },
    });
    ordem++;
  });

  return result;
}

/**
 * Salva o aceite do termo
 * @param chave chave da proposta/vistoria
 * @param idEtapa id da etapa da vistoria
 */
export async function saveAceiteTermo(chave: string, idEtapa: string) {
  const dataHoraAceite = new Date();
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('[idEtapa+chave]')
    .equals([idEtapa, chave])
    .first();

  if (vistoriaEtapas) {
    vistoriaEtapas.contrato!.aceite = true;
    vistoriaEtapas.contrato!.dataHoraAceite = dataHoraAceite;
    vistoriaEtapas.sync = false;
    vistoriaEtapas.prepared = true;

    await db.vistoriaEtapas.update([idEtapa, chave], vistoriaEtapas);

    await updateVistoriaEtapasStore(chave);
  }
}

/**
 * Recupera as Etapas Termos não sicronizadas
 * @param chave da proposta/vistoria
 */
export async function getVistoriaEtapasTermosNotSync(chave: string) {
  const result: VistoriaEtapas[] = [];
  const vistoriaEtpas = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .toArray();

  vistoriaEtpas.forEach((element) => {
    if (element.tipo === 'TERMO') {
      if (element.prepared && !element.sync) {
        result.push(element);
      }
    }
  });

  return result;
}
