<script setup lang="ts">
//#region Import

import { onBeforeUnmount, onMounted, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';

// Service / Store
import { goVerificar } from '@/shared/utils/routeNavigate';
import { saveVideo } from '@/service/vistoria-files';
import { useStepVistoria } from '@/stores/stepVistoria';

// Component
import Loading from '@/shared/component/show-loading.vue';
import ModalWaitSaveFile from '@/shared/component/modal-wait-save-file.vue';

// Other
import {
  MEDIA_STREAM_CONSTRAINTS_VIDEO,
  MAX_SECONDS_VIDEO,
  ID_MODELO_VIDEO_ADICIONAL,
} from '@/global';
import { mimeTypeSuported } from '@/service/files';

//#endregion

const vistoriaStore = useStepVistoria();

const videoElement = ref<HTMLVideoElement>();

const recording = ref<boolean>(false);
const timer = ref<number>(0);
const minutes = ref<string>('00');
const seconds = ref<string>('00');

const showCamera = ref<boolean>(false);
const showButtonCapture = ref<boolean>(false);
const loading = ref<boolean>(true);
const showModal = ref<boolean>(false);

let cameraStream: MediaStream;
let mediaRecorder: MediaRecorder;
let blobsRecorded: Blob[] = [];
let mimeType: string = mimeTypeSuported();
let timerInterval: any;

/**
 * Abre o Stream da Camera
 */
const openStreamCamera = async () => {
  loading.value = true;
  showCamera.value = true;

  cameraStream = await navigator.mediaDevices.getUserMedia(
    MEDIA_STREAM_CONSTRAINTS_VIDEO,
  );
  videoElement.value!.srcObject = cameraStream;
  showButtonCapture.value = true;
  loading.value = false;
};

/**
 * Fecha o Stream da Camera
 */
const closeStreamCamera = () => {
  return new Promise<void>((resolve) => {
    showCamera.value = false;
    showButtonCapture.value = false;

    if (!cameraStream) {
      return resolve();
    }

    const tracks = cameraStream.getTracks();

    tracks.forEach((track: any) => {
      track.stop();
    });

    if (videoElement.value) {
      videoElement.value!.pause();
      videoElement.value!.srcObject = null;
    }

    resolve();
  });
};

/**
 * Iniciar o timer
 */
const startTimer = () => {
  timer.value = 0;

  timerInterval = setInterval(() => {
    timer.value++;

    minutes.value = Math.floor(timer.value / 60)
      .toString()
      .padStart(2, '0');
    seconds.value = (timer.value % 60).toString().padStart(2, '0');

    if (timer.value >= MAX_SECONDS_VIDEO) {
      maxVideoTimeTarget();
    }
  }, 1000);
};

/**
 * Finalizar o timer
 */
const stopTimer = () => {
  clearInterval(timerInterval);
};

/**
 * Tempo máximo de vídeo atingido
 */
const maxVideoTimeTarget = () => {
  captureVideo();
};

/**
 * Inicia/Termina a captura do vídeo
 */
const captureVideo = async () => {
  recording.value = !recording.value;

  if (recording.value) {
    await playSound();
    mediaRecorder = new MediaRecorder(cameraStream, { mimeType });

    mediaRecorder.addEventListener('dataavailable', function (e: BlobEvent) {
      blobsRecorded.push(e.data);
    });

    mediaRecorder.start(1000);
  }

  if (!recording.value) {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.addEventListener('stop', async function () {
        showModal.value = true;
        await saveFileAndRedirect(blobsRecorded, mimeType);
      });

      mediaRecorder.stop();
    }
  }

  recording.value ? startTimer() : stopTimer();
};

/**
 * Salva o arquivo e redireciona
 * @param value video em Blob
 * @param type minetype do video
 */
const saveFileAndRedirect = async (value: Blob[], type: string) => {
  let idFotoAdicional;

  if (
    vistoriaStore.currentVistoriaEtapa!.fotoVideo?.idModeloFoto ===
    ID_MODELO_VIDEO_ADICIONAL
  ) {
    idFotoAdicional =
      vistoriaStore.currentVistoriaEtapa!.fotoVideo?.idFotoAdicional ||
      uuidv4();
  }

  await saveVideo(
    vistoriaStore.currentVistoriaEtapa!.idEtapa,
    vistoriaStore.vistoria!.chave,
    value,
    type,
    idFotoAdicional,
  );

  goVerificar();
};

/**
 * Play do audio
 */
const playSound = async () => {
  const audioUrl = new URL('@/assets/mp3/video-capture.mp3', import.meta.url)
    .href;
  const audio = new Audio(audioUrl);
  await audio.play();
};

onMounted(async () => {
  await openStreamCamera();
});

onBeforeUnmount(async () => {
  await closeStreamCamera();
});
</script>

<template>
  <Loading :show="loading" />

  <ModalWaitSaveFile :show="showModal" />

  <div class="capture-video">
    <div class="capture-video__timer fade-in" v-if="showButtonCapture">
      <span>{{ minutes }}:{{ seconds }}</span>
    </div>

    <div class="capture-video__buttons">
      <div
        class="capture-video__buttons__capture-video fade-in"
        :class="{
          'capture-video__buttons__capture-video--active pulsate-fwd':
            recording,
        }"
        v-if="showButtonCapture"
      >
        <span @click="captureVideo()"></span>
      </div>
    </div>

    <video
      v-if="showCamera"
      ref="videoElement"
      playsInline="true"
      autoPlay="true"
      muted="true"
    ></video>
  </div>
</template>

<style lang="scss" scoped>
.capture-video {
  background-color: #212322;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    max-width: 100%;
    max-height: 100%;
  }

  &__timer {
    position: absolute;
    z-index: 1;
    top: 0.2rem;
    color: var(--white);
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: center;

    span {
      background-color: rgba($color: #000000, $alpha: 0.5);
      padding: 0.2rem 0.4rem;
      border-radius: 0.4rem;
      display: block;
      width: 3rem;
      text-align: center;
    }
  }

  &__buttons {
    position: absolute;
    z-index: 1;
    bottom: 1.2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__capture-video {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        cursor: pointer;
        display: block;
        width: 3.6rem;
        height: 3.6rem;
        background: rgba(255, 3, 3, 0.3);
        border: 3px solid #ff0303;
        border-radius: 50%;
      }

      &--active {
        span {
          background-color: rgb(255, 3, 3);
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .capture-video {
    &__buttons {
      right: 1.2rem;
      width: initial;
      height: 100%;
      bottom: 0;
    }
  }
}

// https://animista.net/play/entrances/fade-in/fade-in
.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// https://animista.net/play/attention/pulsate/pulsate-fwd
.pulsate-fwd {
  animation: pulsate-fwd 1s ease-in-out infinite both;
}

@keyframes pulsate-fwd {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
