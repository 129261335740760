<script lang="ts" setup>
//#region Imports

import { onMounted, ref } from 'vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { vMaska } from 'maska';

// Model
import type { VistoriaEtapaDadosImplementoAgregado } from '@/model/vistoria-etapas.interface';

// Service
import { getAssuntoImplementoAgregado } from './utils';
import { saveDadosImplementoAgregado } from '@/service/vistoria-etapas/dados-implemento-agregado';
import { useStepVistoria } from '@/stores/stepVistoria';

// Component
import IconCheck from '@/shared/icons/IconCheck.vue';
import Loading from '@/shared/component/show-loading.vue';

// Other
import { validateChassi, validatePlaca } from '@/shared/validate';

//#endregion

const vistoriaStore = useStepVistoria();
const loading = ref<boolean>(true);
const assunto = ref<string>();

const state = ref<VistoriaEtapaDadosImplementoAgregado>({
  idPropostaComplemento: 0,
  descricao: '',
  marca: '',
  modelo: '',
  validatedForm: false,
});

const validationRules = {
  descricao: {
    required,
  },
  modelo: {
    required,
  },
  marca: {
    required,
  },
  placa: {
    validatePlaca,
  },
  chassi: {
    validateChassi,
  },
};

// @ts-ignore
const v$ = useVuelidate(validationRules, state, { $autoDirty: true });

/**
 * Avança para o próximo etapa
 */
const nextStep = async () => {
  const chave = vistoriaStore!.vistoria!.chave;
  const idEtapa = vistoriaStore!.currentVistoriaEtapa!.idEtapa;
  const dados: VistoriaEtapaDadosImplementoAgregado = Object.assign(
    {},
    state.value,
  );
  const tipo = vistoriaStore!.currentVistoriaEtapa!.tipo as
    | 'DADOS_IMPLEMENTO'
    | 'DADOS_AGREGADO';

  const validForm = await v$.value.$validate();

  if (validForm) {
    dados.validatedForm = true;
    await saveDadosImplementoAgregado(chave, idEtapa, dados, tipo);
    await vistoriaStore.nextVistoriaEtapa();
  }
};

/**
 * Recupera os dados preenchidos anteriormente
 */
const getPreviousData = () => {
  const dados =
    vistoriaStore.currentVistoriaEtapa?.dadosImplemento ??
    vistoriaStore.currentVistoriaEtapa?.dadosAgregado;

  if (dados) {
    state.value = Object.assign({}, dados);
  }
  state.value.idPropostaComplemento = dados!.idPropostaComplemento;
};

onMounted(async () => {
  getPreviousData();

  assunto.value = getAssuntoImplementoAgregado(
    vistoriaStore!.currentVistoriaEtapa,
  );

  // Tempo para atualizar front
  setTimeout(() => {
    loading.value = false;
  }, 300);
});
</script>

<template>
  <Loading :show="loading" />

  <div class="container implemento-agregado__container">
    <span class="title">{{
      vistoriaStore!.currentVistoriaEtapa?.nomeEtapa
    }}</span>

    <span class="texto"
      >Preencha com os dados do {{ assunto?.toLowerCase() }}</span
    >

    <div class="implemento-agregado__container__form">
      <!-- Descrição -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.descricao?.$errors.length }"
      >
        <label class="form-label label-sm" for="input-descricao"
          >Descrição:*</label
        >
        <input
          class="form-input"
          type="text"
          id="input-descricao"
          placeholder="Informe o Descrição"
          v-model="state.descricao"
          data-testId="description-input"
        />
        <p class="form-input-hint">Descrição inválido</p>
      </div>

      <!-- Modelo -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.modelo?.$errors.length }"
      >
        <label class="form-label label-sm" for="input-modelo">Modelo:*</label>
        <input
          class="form-input"
          type="text"
          id="input-modelo"
          placeholder="Informe o Modelo"
          v-model="state.modelo"
          data-testId="model-input"
        />
        <p class="form-input-hint">Modelo inválido</p>
      </div>

      <!-- Marca -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.marca?.$errors.length }"
      >
        <label class="form-label label-sm" for="input-marca">Marca:*</label>
        <input
          class="form-input"
          type="text"
          id="input-marca"
          placeholder="Informe o Marca"
          v-model="state.marca"
          data-testId="brand-input"
        />
        <p class="form-input-hint">Marca inválido</p>
      </div>

      <!-- Placa -->
      <div class="form-group" :class="{ 'has-error': v$.placa.$errors.length }">
        <label class="form-label label-sm" for="input-placa">Placa:</label>
        <input
          class="form-input"
          type="text"
          id="input-placa"
          placeholder="Informe a placa antiga"
          v-maska
          data-maska="@@@-****"
          v-model="state.placa"
          data-testId="license-plate-input"
        />
        <p class="form-input-hint">Placa inválido</p>
      </div>

      <!-- Chassi -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.chassi.$errors.length }"
      >
        <label class="form-label label-sm" for="input-chassi">Chassi:</label>
        <input
          class="form-input"
          type="text"
          id="input-chassi"
          placeholder="Informe o número do chassi"
          v-model="state.chassi"
          data-testId="chassis-input"
        />
        <p class="form-input-hint">Chassi inválido</p>
      </div>

      <!-- observacao -->
      <div class="form-group">
        <label class="form-label label-sm" for="input-observacao"
          >Observacao:</label
        >
        <textarea
          class="form-input"
          id="input-example-3"
          rows="3"
          placeholder="Informe o Observacao"
          v-model="state.observacao"
          data-testId="observation-input"
        ></textarea>
      </div>
    </div>

    <div class="btn-vistoria container__next">
      <button
        class="btn btn-primary"
        @click="nextStep()"
        :disabled="v$.$invalid"
        data-testId="next-step-button"
      >
        <IconCheck />
        <span>Salvar Dados</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.implemento-agregado__container {
  margin: 0.8rem auto;

  span {
    display: block;
  }

  &__form {
    margin-top: 0.8rem;
    margin-bottom: 1.6rem;

    #input-placa,
    #input-chassi {
      text-transform: uppercase;
    }

    input::placeholder {
      text-transform: none;
    }

    .form-input-hint {
      display: none;
    }

    .has-error {
      .form-input-hint {
        display: block;
      }
      p.form-input-hint {
        margin-bottom: 0;
      }
    }

    .divider {
      margin-top: 1.2rem;
    }
  }
}
</style>
