import { createApp } from 'vue';
// import * as Sentry from '@sentry/vue';

import store from './stores/stepVistoria';

// @ts-ignore
import VueSignaturePad from 'vue-signature-pad';

import App from './App.vue';
import router from './router';

import './style/main.scss';

const app = createApp(App);

// Sentry.init({
//   app,
//   dsn: 'https://985bcf30039b4377abfd2055665fdf47@o4505312900284416.ingest.sentry.io/4505312903495680',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         'localhost',
//         /^https:\/\/vistoria\.bemprotege\.com\.br/,
//         /^https:\/\/vistoria\.evogerencial\.com\.br/,
//       ],
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

app.use(store);
app.use(router);
app.use(VueSignaturePad);

app.mount('#app');
