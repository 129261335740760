<script lang="ts" setup>
//#region Imports

import { computed, onMounted, ref } from 'vue';
import { required, email, minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { vMaska } from 'maska';

// Model
import type { VistoriaEtapaDadosPessoais } from '@/model/vistoria-etapas.interface';

// Service
import { saveDadosPessoais } from '@/service/vistoria-etapas/dados-pessoais';
import { useStepVistoria } from '@/stores/stepVistoria';

// Component
import IconCheck from '@/shared/icons/IconCheck.vue';
import Loading from '@/shared/component/show-loading.vue';

// Other
import {
  PESSOA_FISICA,
  PESSOA_JURIDICA,
  CASADO,
  LIST_SEXO,
  LIST_ESTADO_CIVIL,
  LIST_REGIME_CASAMENTO,
} from '@/global';
import { validateDate, validateCPF } from '@/shared/validate';

//#endregion

const vistoriaStore = useStepVistoria();
const loading = ref<boolean>(true);

const state = ref<VistoriaEtapaDadosPessoais>({
  idProspect: 0,
  nome: '',
  email: '',
  telefone: null,
  celular: '',
  tipo: 'Pessoa Física',
  cpfcnpj: '',
  rgIe: '',
  sexo: '',
  estadoCivil: '',
  regimeCasamento: null,
  validatedForm: false,
});

const validationRules = computed(() => {
  let localRules = {
    nome: { required },
    email: { required, email },
    celular: { required, minLength: minLength(15) },
  };

  if (state.value.tipo === PESSOA_FISICA) {
    const validatePessoaFisica = {
      rgIe: { required },
      sexo: { required },
      estadoCivil: { required },
      dataNascimento: {
        required,
        validateDate,
      },
    };

    localRules = Object.assign(localRules, validatePessoaFisica);
  }

  if (state.value.tipo === PESSOA_JURIDICA) {
    const validatePessoaJuridica = {
      representanteLegal: {
        required,
        minLength: minLength(3),
      },
      cpfRepresentante: {
        required,
        validateCPF,
      },
    };

    localRules = Object.assign(localRules, validatePessoaJuridica);
  }

  if (state.value.estadoCivil === CASADO) {
    const validateRegimeCasamento = {
      regimeCasamento: { required },
    };

    localRules = Object.assign(localRules, validateRegimeCasamento);
  }

  return localRules;
});

const v$ = useVuelidate(validationRules, state, { $autoDirty: true });

/**
 * Sanitiza os dados conforme parâmetro 'tipo' e 'estadoCivil'
 */
const sanitize = () => {
  if (state.value.tipo === PESSOA_FISICA) {
    state.value.representanteLegal = null;
    state.value.cpfRepresentante = null;
  }

  if (state.value.tipo === PESSOA_JURIDICA) {
    state.value.dataNascimento = null;
  }

  if (state.value.estadoCivil !== CASADO) {
    state.value.regimeCasamento = null;
  }
};

/**
 * Avança para o próximo etapa
 */
const nextStep = async () => {
  const chave = vistoriaStore!.vistoria!.chave;
  const idEtapa = vistoriaStore!.currentVistoriaEtapa!.idEtapa;
  const dados: VistoriaEtapaDadosPessoais = Object.assign({}, state.value);

  const validForm = await v$.value.$validate();

  if (validForm) {
    dados.validatedForm = true;
    await saveDadosPessoais(chave, idEtapa, dados);
    await vistoriaStore.nextVistoriaEtapa();
  }
};

/**
 * Recupera os dados preenchidos anteriormente
 */
const getPreviousData = () => {
  const dadosPessoais = vistoriaStore.currentVistoriaEtapa?.dadosPessoais;

  if (dadosPessoais) {
    state.value = Object.assign({}, dadosPessoais);
  }

  state.value.idProspect = vistoriaStore.vistoria!.idProspect;
  sanitize();
};

onMounted(async () => {
  getPreviousData();

  // Tempo para atualizar front
  setTimeout(() => {
    loading.value = false;
  }, 300);
});
</script>

<template>
  <Loading :show="loading" />

  <div class="container pessoais__container">
    <span class="title">Dados Pessoais</span>

    <span class="texto">Preencha com os dados pessoais</span>

    <div class="pessoais__container__form">
      <!-- Nome -->
      <div class="form-group" :class="{ 'has-error': v$.nome?.$errors.length }">
        <label class="form-label label-sm">Nome Completo:*</label>
        <input class="form-input" type="text" v-model="state.nome" />
        <p class="form-input-hint">Nome inválido</p>
      </div>

      <!-- CPF / CNPJ -->
      <div class="form-group">
        <label class="form-label label-sm"
          >{{ state.tipo === PESSOA_JURIDICA ? 'CNPJ' : 'CPF' }}*</label
        >
        <input
          class="form-input"
          type="text"
          disabled
          v-model="state.cpfcnpj"
          data-testId="cpf-cnpj-input"
        />
      </div>

      <!-- E-mail -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.email?.$errors.length }"
      >
        <label class="form-label label-sm" for="input-email">E-mail:*</label>
        <input
          class="form-input"
          type="email"
          id="input-email"
          placeholder="Infomre o seu e-mail"
          v-model="state.email"
          data-testId="email-input"
        />
        <p class="form-input-hint">E-mail inválido</p>
      </div>

      <!-- Celular -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.celular?.$errors.length }"
      >
        <label class="form-label label-sm" for="input-celular">Celular:*</label>
        <input
          class="form-input"
          type="tel"
          id="input-celular"
          placeholder="Infomre o número do seu celular"
          v-maska
          data-maska="(##) #####-####"
          v-model="state.celular"
          data-testId="cellphone-input"
        />
        <p class="form-input-hint">Celular inválido</p>
      </div>

      <!-- Telefone -->
      <div class="form-group">
        <label class="form-label label-sm" for="input-telefone">Telefone</label>
        <input
          class="form-input"
          type="tel"
          id="input-telefone"
          placeholder="Infomre o número do seu telefone"
          v-maska
          data-maska='["(##) ####-####", "(##) #####-####"]'
          v-model="state.telefone"
          data-testId="phone-input"
        />
      </div>

      <!-- Pessoa Física -->
      <div v-if="state.tipo === PESSOA_FISICA">
        <!-- Data de Nascimento -->
        <div
          class="form-group"
          :class="{ 'has-error': v$.dataNascimento?.$errors.length }"
        >
          <label class="form-label label-sm" for="input-datanascimento"
            >Data de Nascimento*</label
          >
          <input
            class="form-input"
            type="tel"
            id="input-datanascimento"
            placeholder="Informa a data de nascimento"
            v-maska
            data-maska="##/##/####"
            v-model="state.dataNascimento"
            data-testId="birth-date-input"
          />
          <p class="form-input-hint">Data nascimento inválida</p>
        </div>

        <!-- RG -->
        <div
          class="form-group"
          :class="{ 'has-error': v$.rgIe?.$errors.length }"
        >
          <label class="form-label label-sm" for="input-rg">RG:*</label>
          <input
            class="form-input"
            type="text"
            id="input-rg"
            placeholder="Infomre o número do seu RG"
            v-model="state.rgIe"
            data-testId="rg-input"
          />
          <p class="form-input-hint">RG inválido</p>
        </div>

        <!-- Sexo -->
        <div
          class="form-group"
          :class="{ 'has-error': v$.sexo.$errors.length }"
        >
          <label class="form-label label-sm">Sexo:*</label>
          <select class="form-select" v-model="state.sexo" data-testId="gender-select">
            <option v-for="(sexo, index) in LIST_SEXO" :key="index">
              {{ sexo }}
            </option>
          </select>
          <p class="form-input-hint">Sexo inválido</p>
        </div>

        <!-- Estado Civíl -->
        <div
          class="form-group"
          :class="{ 'has-error': v$.estadoCivil.$errors.length }"
        >
          <label class="form-label label-sm">Estado Civil:*</label>
          <select class="form-select" v-model="state.estadoCivil" data-testId="marital-status-select">
            <option
              v-for="(estadoCivil, index) in LIST_ESTADO_CIVIL"
              :key="index"
            >
              {{ estadoCivil }}
            </option>
          </select>
          <p class="form-input-hint">Estado civil inválido</p>
        </div>

        <!-- Regime de casamento -->
        <div v-if="state.estadoCivil === CASADO">
          <div
            class="form-group"
            :class="{ 'has-error': v$.regimeCasamento.$errors.length }"
          >
            <label class="form-label label-sm">Regime Casamento:*</label>
            <select class="form-select" v-model="state.regimeCasamento" data-testId="marital-regime-select">
              <option
                v-for="(regimeCasamento, index) in LIST_REGIME_CASAMENTO"
                :key="index"
              >
                {{ regimeCasamento }}
              </option>
            </select>
            <p class="form-input-hint">Regime de casamento inválido</p>
          </div>
        </div>
      </div>

      <!-- Pessoa Jurídica -->
      <div v-if="state.tipo === PESSOA_JURIDICA">
        <!-- IE -->
        <div class="form-group">
          <label class="form-label label-sm" for="input-rg"
            >Inscrição Estadual:*</label
          >
          <input
            class="form-input"
            type="tel"
            id="input-rg"
            placeholder="Infomre o número da IE"
            v-model="state.rgIe"
            data-testId="state-registration-input"
          />
        </div>

        <!-- Representante Legal -->
        <div
          class="form-group"
          :class="{ 'has-error': v$.representanteLegal?.$errors.length }"
        >
          <label class="form-label label-sm" for="input-representanteLegal"
            >Nome do Representante Legal:*</label
          >
          <input
            class="form-input"
            type="text"
            id="input-representanteLegal"
            placeholder="Informa o representante legal"
            v-model="state.representanteLegal"
            data-testId="legal-representative-input"
          />
          <p class="form-input-hint">Representante Legal inválido</p>
        </div>

        <!-- CPF Representante Legal -->
        <div
          class="form-group"
          :class="{ 'has-error': v$.cpfRepresentante?.$errors.length }"
        >
          <label class="form-label label-sm" for="input-cpfRepresentante"
            >CPF do Representante Legal:*</label
          >
          <input
            class="form-input"
            type="tel"
            id="input-cpfRepresentante"
            placeholder="Informa o CPF do representante legal"
            v-maska
            data-maska="###.###.###-##"
            v-model="state.cpfRepresentante"
            data-testId="legal-representative-cpf-input"
          />
          <p class="form-input-hint">CPF inválido</p>
        </div>
      </div>
    </div>

    <div class="btn-vistoria container__next">
      <button
        class="btn btn-primary"
        @click="nextStep()"
        :disabled="v$.$invalid"
        data-testId="save-data-button"
      >
        <IconCheck />
        <span>Salvar Dados</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pessoais__container {
  margin: 0.8rem auto;

  span {
    display: block;
  }

  &__form {
    margin-top: 0.8rem;
    margin-bottom: 1.6rem;

    .form-input-hint {
      display: none;
    }

    .has-error {
      .form-input-hint {
        display: block;
      }
      p.form-input-hint {
        margin-bottom: 0;
      }
    }

    .divider {
      margin-top: 1.2rem;
    }
  }
}
</style>
