<script setup lang="ts">
//#region Import's

import { onMounted, onUnmounted, ref } from 'vue';

// Service
import { saveFileBase64 } from '@/service/vistoria-files';
import { useStepVistoria } from '@/stores/stepVistoria';

// Component
import Loading from '@/shared/component/show-loading.vue';

//#endregion

const vistoriaStore = useStepVistoria();
const signaturePad = ref();
const loading = ref<boolean>(false);
const showModal = ref<boolean>(false);

/**
 * Limpa a assinatura
 */
const clearSignature = () => {
  signaturePad.value.clearSignature();
};

/**
 * Salva a assinatura
 */
const saveSignature = async () => {
  const { isEmpty, data } = signaturePad.value.saveSignature();

  if (isEmpty) {
    return;
  }

  loading.value = true;

  await saveFileBase64(
    vistoriaStore.currentVistoriaEtapa!.idEtapa,
    vistoriaStore.vistoria!.chave,
    data,
  );

  await vistoriaStore.nextVistoriaEtapa();
};

const clearSignatureOrientationChange = () => {
  if (signaturePad.value && !signaturePad.value.isEmpty()) {
    showModal.value = true;
    signaturePad.value.clearSignature();
  }
};

onMounted(() => {
  window.addEventListener('orientationchange', clearSignatureOrientationChange);
});

onUnmounted(() => {
  window.removeEventListener(
    'orientationchange',
    clearSignatureOrientationChange,
  );
});
</script>

<template>
  <Loading :show="loading" />

  <div class="assinatura">
    <!-- Modal -->
    <div class="modal active" id="modal-id" v-if="showModal">
      <div class="modal-container">
        <div class="modal-body">
          <div class="content">
            <span
              >Precisamos que você refaça a sua assinatura e clique no botão
              "Continuar"</span
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary"
            @click="() => (showModal = !showModal)"
          >
            OK
          </button>
        </div>
      </div>
    </div>

    <!-- Assinatura -->
    <div class="assinatura__canva-assinatura">
      <div class="assinatura__canva-assinatura__canvas force-background-white">
        <VueSignaturePad ref="signaturePad" />
        <div class="assinatura__canva-assinatura__canvas__nome-associado">
          {{ vistoriaStore.vistoria?.nome }}
        </div>
      </div>
      <div class="assinatura__canva-assinatura__button">
        <button class="btn" @click="clearSignature()">Refazer</button>
        <button class="btn btn-primary" @click="saveSignature()">
          Continuar
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.assinatura {
  height: 100%;
  width: 100%;

  &__canva-assinatura {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__canvas {
      flex: 1;
      position: relative;
      overflow: hidden;

      &__nome-associado {
        position: absolute;
        bottom: 0.4rem;
        width: 100%;
        border-top: 1px solid #000;
        padding: 0.4rem;
        text-align: center;
        margin: 0 auto;
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      gap: 0.8rem;
      padding: 0.4rem;

      button {
        min-width: 40%;
      }
    }
  }
}
</style>
