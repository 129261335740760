import { v4 as uuidv4 } from 'uuid';

// Model
import type { Vistoria } from '@/model/vistoria.interface';
import type {
  VistoriaEtapaDadosEndereco,
  VistoriaEtapas,
} from '@/model/vistoria-etapas.interface';

// Service
import { db } from '../db';
import { doRequest } from '../http';
import { updateVistoriaEtapasStore } from '@/service/store';

// Other
import {
  STATUS_PERMITE_EDICAO_DADOS,
  TIPO_ETAPA_DADOS_ENDERECO,
} from '@/global';

/**
 * Recupera a Etapa Dados Endereço para salvar no IndexedDB
 * @param vistoria dados da vistoria
 * @param ordem ordem da vistoria
 */
export function getVistoriaEtapaDadosEnderecoSaveIndexedDB(
  vistoria: Vistoria,
  ordem: number,
) {
  const idStatus = vistoria.id_Status;

  if (!STATUS_PERMITE_EDICAO_DADOS.includes(idStatus)) {
    return null;
  }

  const chave = vistoria.chave;
  const sync = vistoria.validatedDadosEndereco;
  const dadosEndereco = vistoria.dadosEndereco;

  dadosEndereco.validatedForm = vistoria.validatedDadosEndereco;

  return {
    idEtapa: uuidv4(),
    chave,
    ordem,
    nomeEtapa: 'Dados do Endereço',
    obrigatorio: true,
    tipo: TIPO_ETAPA_DADOS_ENDERECO,
    sync,
    dadosEndereco,
  } as VistoriaEtapas;
}

/**
 * Salvar os dados do endereço
 * @param chave chave da proposta/vistoria
 * @param idEtapa id da etapa da vistoria
 * @param dados dados do endereço
 */
export async function saveDadosEndereco(
  chave: string,
  idEtapa: string,
  dados: VistoriaEtapaDadosEndereco,
) {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('[idEtapa+chave]')
    .equals([idEtapa, chave])
    .first();

  if (vistoriaEtapas) {
    vistoriaEtapas!.dadosEndereco = dados;
    vistoriaEtapas!.prepared = true;
    vistoriaEtapas!.sync = false;

    await db.vistoriaEtapas.update([idEtapa, chave], vistoriaEtapas);

    await updateVistoriaEtapasStore(chave);
  }
}

/**
 * Recupera a Etapa Dados Endereço não sincronizada
 * @param chave da proposta/vistoria
 */
export async function getVistoriaEtapaDadosEnderecoNotSync(chave: string) {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .toArray();

  const vistoriaEtapa = vistoriaEtapas.find(
    (x) => x.tipo === TIPO_ETAPA_DADOS_ENDERECO,
  );

  if (vistoriaEtapa) {
    const result = vistoriaEtapa?.dadosEndereco || null;

    if (!result) {
      return result;
    }

    if (!vistoriaEtapa.sync && vistoriaEtapa.prepared) {
      return vistoriaEtapa;
    }
  }

  return null;
}

/**
 * Recupera dados do endereço pelo CEP
 */
export async function getDataCEP(cep: string) {
  return new Promise<any | null>((resolve, reject) => {
    const url = `${import.meta.env.VITE_URL_API}/Cep/BuscaCEP`;
    const method = 'POST';
    const data = { cep };

    const requestOptions = {
      url,
      method,
      data,
    };

    doRequest(requestOptions).then(async (res) => {
      if (res.error) {
        return reject(res.error);
      }

      if (res.data) {
        const result = res.data;

        resolve(result);
      }

      resolve(null);
    });
  });
}
