import { v4 as uuidv4 } from 'uuid';

// Model
import type { Vistoria } from '@/model/vistoria.interface';
import type { VistoriaEtapas } from '@/model/vistoria-etapas.interface';

// Other
import {
  ID_MODELO_FOTO_ASSINATURA,
  NOME_MODELO_FOTO_ASSINATURA,
  TIPO_ETAPA_ASSINATURA,
} from '@/global';

/**
 * Recupera a Etapa Assinatura para salvar no IndexedDB
 * @param vistoria dados da vistoria
 * @param ordem ordem da vistoria
 */
export function getVistoriaEtapaAssinaturaSaveIndexedDB(
  vistoria: Vistoria,
  ordem: number,
) {
  const chave = vistoria.chave;

  const redo = vistoria.realizarAssinatura;
  let sync = false;
  let nome;

  if (vistoria?.assinatura) {
    sync = true;
    nome = {
      id: `assinatura_${vistoria.idProposta}`,
      url: vistoria.assinatura,
    };
  }

  return {
    idEtapa: uuidv4(),
    chave,
    ordem,
    nomeEtapa: NOME_MODELO_FOTO_ASSINATURA,
    obrigatorio: true,
    tipo: TIPO_ETAPA_ASSINATURA,
    sync,
    redo,
    fotoVideo: {
      aprovada: null,
      idModeloFoto: ID_MODELO_FOTO_ASSINATURA,
      obrigatorio: true,
      nome,
    },
  } as VistoriaEtapas;
}
