<script setup lang="ts">
defineProps({
  show: Boolean,
});

const emit = defineEmits(['close']);

const closeModal = () => {
  emit('close');
};
</script>

<template>
  <div class="modal modal-sm" :class="{ active: show }">
    <div class="modal-overlay"></div>
    <div class="modal-container">
      <div class="modal-header">
        <div class="modal-title h5">Ops!!!</div>
      </div>
      <div class="modal-body">
        <div class="content">
          <span class="texto">Arquivo selecionado inválido</span>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn" @click="closeModal()">Fechar</button>
      </div>
    </div>
  </div>
</template>
