import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as cnh from 'validation-br/dist/cnh';
import * as renvam from 'validation-br/dist/renavam';
import * as cpf from 'validation-br/dist/cpf';

/**
 * Valida se a CNH está valida
 * @param value valor a ser validado
 */
export function validateCnhVencidade(value: string, data: any) {
  if (data.naoPossuiCnh) {
    return true;
  }

  const dateVenc = dayjs(value, 'DD/MM/YYYY', true).toDate();
  const dateNow = new Date();
  const oneDay = 24 * 60 * 60 * 1000;

  const diffDays = Math.round(
    (dateNow.getTime() - dateVenc.getTime()) / oneDay,
  );

  return !(diffDays > 30);
}

/**
 * Valida se é uma data valida
 * @param value valor a ser validado
 */
export function validateDate(value: string, data: any) {
  if (data.naoPossuiCnh) {
    return true;
  }

  dayjs.extend(customParseFormat);
  return dayjs(value, 'DD/MM/YYYY', true).isValid();
}

/**
 * Valida se é um CNH valida
 * @param value valor a ser validado
 */
export function validateCNH(value: string, data: any) {
  if (data.naoPossuiCnh) {
    return true;
  }

  return cnh.validate(value);
}

/**
 * Valida se é um RENAVAM valido
 * @param value valor a ser validado
 */
export function validateRENAVAM(value: string) {
  if (!value) {
    return true;
  }

  return renvam.validate(value);
}

/**
 * Valida se o ano do DUT é menor que 3 anos
 * @param value valor a ser validado
 */
export function validateAnoDUT(value: number) {
  if (value.toString().length === 4) {
    const currentDate = new Date();

    return !(currentDate.getFullYear() - value >= 4);
  }

  return false;
}

/**
 * Valida se é um CPF válido
 * @param value valor a ser validado
 */
export function validateCPF(value: string) {
  if (!value) {
    return true;
  }

  return cpf.validate(value);
}

/**
 * Valida se é um Chassi válido
 * https://forum.blip.ai/t/validacao-de-chassi/21151/2
 * @param value valor a ser validado
 */
export function validateChassi(value: string) {
  if (!value) {
    return true;
  }

  const regex = new RegExp(
    '^[A-Za-z0-9]{3,3}[A-Za-z0-9]{6,6}[A-Za-z0-9]{2,2}[A-Za-z0-9]{6,6}$',
  );
  return regex.test(value);
}

/**
 * Valida se é uma placa válida
 * https://ricardo.coelho.eti.br/regex-mercosul/
 * @param value valor a ser validado
 */
export function validatePlaca(value: string) {
  if (!value) {
    return true;
  }

  const regex = new RegExp('[A-Z]{3}-[0-9][0-9A-Z][0-9]{2}');
  return regex.test(value.toUpperCase());
}

/**
 * Valida se a placa substituída diferente da placa contratada
 * @param param placa contratada
 */
export function validatePlacaSubstituicao(param?: string) {
  return function (value: string) {
    return param?.toUpperCase() !== value.toUpperCase();
  };
}
