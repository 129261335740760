import { db } from '../db';
import { v4 as uuidv4 } from 'uuid';

// Model
import type {
  VistoriaEtapaCacheFile,
  VistoriaEtapas,
} from '@/model/vistoria-etapas.interface';
import type { VistoriaFotosResponse } from '@/model/vistoria-fotos-response.interface';

// Other
import {
  ID_MODELO_FOTO_ADICIONAL,
  ID_MODELO_VIDEO,
  ID_MODELO_VIDEO_ADICIONAL,
  TIPO_ETAPA_ASSINATURA,
  TIPO_ETAPA_FOTO,
  TIPO_ETAPA_VIDEO,
  URL_S3_FOTOS_PADRAO,
} from '@/global';

//#region Private Functions

/**
 * Recupera o tipo (Foto ou Vídeo)
 * @param idModeloFoto id do modelo da foto
 * @returns
 */
const getTipoFotoVideo = (idModeloFoto: number): 'VIDEO' | 'FOTO' => {
  if (ID_MODELO_VIDEO.includes(idModeloFoto)) {
    return TIPO_ETAPA_VIDEO;
  }

  return TIPO_ETAPA_FOTO;
};

/**
 * Recupera Dados para realizar o Cache
 * @param url url do arquivo
 * @returns
 */
const cacheFileBuilder = (url: string): VistoriaEtapaCacheFile | undefined => {
  if (!url) {
    return undefined;
  }

  const arrayURL = url.split('/');
  let nameFile = arrayURL.at(-1);

  if (!nameFile) {
    return undefined;
  }

  if (nameFile.indexOf('.')) {
    nameFile = nameFile.split('.')[0];
  }

  return {
    id: nameFile,
    url,
  };
};

//#endregion

/**
 * Recupera as Etapas Fotos/Videos para salvar no IndexedDB
 * @param chave da proposta/vistoria
 * @param vistoriaFotosResponse retorno API
 * @param ordem valor inicial da ordem da etapa
 * @param filterAdicional recupera apenas as fotos Adicionais
 */
export function getVistoriaEtapaFotosVideosSaveIndexedDB(
  chave: string,
  vistoriaFotosResponse: VistoriaFotosResponse[],
  ordem: number,
  filterAdicional = false,
) {
  const result: VistoriaEtapas[] = [];

  if (filterAdicional) {
    vistoriaFotosResponse = vistoriaFotosResponse.filter((x) =>
      [ID_MODELO_FOTO_ADICIONAL, ID_MODELO_VIDEO_ADICIONAL].includes(
        x.id_modelofoto,
      ),
    );
  } else {
    vistoriaFotosResponse = vistoriaFotosResponse.filter(
      (x) =>
        ![ID_MODELO_FOTO_ADICIONAL, ID_MODELO_VIDEO_ADICIONAL].includes(
          x.id_modelofoto,
        ),
    );
  }

  vistoriaFotosResponse.forEach((element) => {
    const tipo = getTipoFotoVideo(element.id_modelofoto);
    const nome = cacheFileBuilder(element.nome);

    // fotoPadrão
    let fotoPadrao;

    if (element.fotoPadrao) {
      fotoPadrao = cacheFileBuilder(
        `${URL_S3_FOTOS_PADRAO}/${element.fotoPadrao}`,
      );
    }

    // Sync
    let sync = false;

    if (nome?.url) {
      sync = true;
    }

    // idFotoAdicional
    let idFotoAdicional;

    if (filterAdicional) {
      idFotoAdicional = element?.idFotoAdicional;
    }

    // Redo
    const redo = element.aprovada === false ? true : undefined;

    const etapa: VistoriaEtapas = {
      idEtapa: uuidv4(),
      chave,
      ordem,
      nomeEtapa: element.nomePosicao,
      obrigatorio: element.obrigatorio,
      tipo,
      sync,
      redo,
      fotoVideo: {
        idModeloFoto: element.id_modelofoto,
        fotoPadrao,
        legenda: element.legenda,
        tipoFoto: element.tipoFoto,
        obrigatorio: element.obrigatorio,
        nome,
        aprovada: element.aprovada,
        motivoReprovacao: element.motivoReprovacao,
        idFotoAdicional,
      },
    };

    result.push(etapa);
    ordem++;
  });

  return result;
}

/**
 * Recupera a lista de pendência por fotos/vídeos/assinatura
 * @param chave da proposta/vistoria
 */
export async function getListFotosVideosRecusadas(chave: string) {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .toArray();
  const fotosPendentes = vistoriaEtapas.filter(
    (x) =>
      x?.redo === true &&
      [TIPO_ETAPA_FOTO, TIPO_ETAPA_VIDEO, TIPO_ETAPA_ASSINATURA].includes(
        x.tipo,
      ),
  );

  return fotosPendentes.map((x) => {
    const message = x.nomeEtapa;
    const motivo = x.fotoVideo?.motivoReprovacao
      ? ` - ${x.fotoVideo?.motivoReprovacao}`
      : '';
    return `${message}${motivo}`;
  });
}
